import {
  createContext,
  useContext,
  ReactNode,
  useRef,
  useState,
  useEffect,
} from "react";
import { iTopDestinationContext } from "./topDestinationContextInterface";
import { iGlobalContext } from "../globalContext/interface";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { StateContext } from "../globalContext/context";
import { useNavigate } from "react-router-dom";
import {
  apiFailedTryAgain,
  checkIfThereIsDestination,
  getConfigMessage,
  getTripName,
  scrollToBottom,
} from "../../utils";
import { iChatData, iCityDetailsResult } from "../../types";
import {
  API_FAILURE_MESSAGE,
  ROUTES,
  SEE_MORE,
  SENDING_CHAT_MESSAGE,
  VIEW_ITINERARY_DETAILS,
} from "../../constant";
import { getPromptAPI } from "../../api/getPrompt";
import { APP_CONFIG, PROMPT } from "../../constant/common";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import toast from "react-hot-toast";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import ReactGA from "react-ga4";
import { planTripDetails } from "../../api/planTripDetails";
import { addHistoryAPI } from "../../api/saveHistory";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { v4 } from "uuid";
let abortPlanTripDetails = new AbortController();

const TopDestinationContext = createContext({} as iTopDestinationContext);

export const TopDestinationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Context
  const state = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    chatData,
    setChatData,
    setSelectedChatData,
    setOpenOverlayScreen,
    enableMultiSelect,
    setenableMultiSelect,
    setIsItinerarySavedFlag,
    setSharedItineraryId,
    isMobile,
    userDetails,
    callSaveHistoryAPIFlag,
    setCallSaveHistoryAPIFlag,
    modifyItineraryPrompt,
    setModifyItineraryPrompt,
    setScreen,
    appConfig,
  }: iGlobalContext = state;

  const navigate = useNavigate();
  const desktopScrollContainerRef: any = useRef(null);
  const [showChatLoaderFlag, setShowChatLoaderFlag] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [disableInput, setDisableInput] = useState<boolean>(false);

  /**
   * Side effect to scroll chat UI to bottom
   */
  useEffect(() => {
    scrollToBottom(desktopScrollContainerRef);
    setModifyItineraryPrompt("");
  }, [modifyItineraryPrompt]);

  /**
   * Opening Trip details for Single or Multi city
   * */
  async function handleViewSingleTripDetails(chatDataArgs: iChatData) {
    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    const tempChatData: iChatData = {
      ...chatDataArgs,
      destinationList: chatDataArgs.destinationList.map(
        (ele: iCityDetailsResult) => ({
          ...ele,
          checkedFlag: isMobile ? true : !ele.checkedFlag,
        })
      ),
    };
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === chatDataArgs.id) return tempChatData;
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (destListEle: iCityDetailsResult) => ({
                ...destListEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );
    const tempTripName = getTripName(chatDataArgs);
    setSelectedChatData({
      ...tempChatData,
      tripName: tempTripName,
    });
    /**
     * Mobile or Desktop
     */
    if (isMobile) {
      navigate(ROUTES.ItineraryDetails);
    } else {
      if (tempChatData.destinationList[0].checkedFlag === false) {
        setScreen("");
      } else {
        setScreen("singleItinerary");
      }
    }
    // Google analytics
    ReactGA.event({
      category: VIEW_ITINERARY_DETAILS.CATEGORY,
      action: VIEW_ITINERARY_DETAILS.ACTION,
      label: ` ${VIEW_ITINERARY_DETAILS.LABEL}  ${tempTripName}`,
    });
  }

  async function handleShowMore(chatDataArgs: iChatData) {
    setTimeout(() => {
      setDisableInput(true);
    }, 200);

    setChatData((prev: iChatData[]) =>
      prev.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === chatDataArgs.id)
          return {
            ...chatDataEle,
            isLoading: true,
          };
        else return chatDataEle;
      })
    );

    const showMorePromptResponse = await getPromptAPI();
    const showMorePrompt = showMorePromptResponse.filter(
      (ele: any) => ele.key === APP_CONFIG.SHOW_MORE
    );
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userDetails?.id || "",
      showMorePrompt.length > 0 ? showMorePrompt[0]?.value : PROMPT.SHOW_MORE,
      chatDataArgs.prompt,
      abortController.signal
    );
    if (!response) {
      toastMessage.info(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      setDisableInput(false);
      return;
    }
    response = checkIfThereIsDestination(response);
    setDisableInput(false);

    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    const tempResponse = citiesList.map((ele: any) => ({
      ...ele,
      id: v4(),
      checkedFlag: false,
      locations: [],
    }));
    setChatData((prev: iChatData[]) =>
      prev.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === chatDataArgs.id)
          return {
            ...chatDataEle,
            fromCity: response?.data?.from,
            noOfDays: response?.data?.numberOfdays,
            message: "",
            preText: response?.data?.preText,
            postText: response?.data?.postText,
            prompt: response?.data?.messages,
            isUserMessage: false,
            isLoading: false,
            singleSelectedDestination: false,
            destinationList: [...chatDataEle.destinationList, ...tempResponse],
          };
        else return chatDataEle;
      })
    );
    const currentItineraryList = tempResponse;
    for (let i = 0; i < currentItineraryList.length; i++) {
      const cityName = currentItineraryList[i].cityName;
      let imageRespone = await getItineraryImageURL(cityName);
      let count = 0;
      while (!imageRespone || !imageRespone?.data?.image) {
        // Limiting the number of API call to 10 times incase of 409 error
        count++;
        if (count === 10) break;
        // re calling the API
        imageRespone = await getItineraryImageURL(cityName);
      }
      if (imageRespone?.statusCode === 200) {
        currentItineraryList[i].imageURL = imageRespone.data.image;
      } else {
        console.log("RESPONSE TOP Error");
      }
    }
    setDisableInput(false);
    setShowChatLoaderFlag(false);
    ReactGA.event({
      category: SEE_MORE.CATEGORY,
      action: SEE_MORE.ACTION,
      label: ` ${SEE_MORE.LABEL}`,
    });
  }

  /**
   * Handling selecting or unselecting
   * card from grid
   */
  async function handleCheckboxChange(
    cityDetails: iCityDetailsResult,
    destinationArgs: iChatData
  ) {
    // setSelectedChatData()
    let tempDestinationList: iCityDetailsResult[] =
      chatData.find((ele: iChatData) => ele.id === destinationArgs.id)
        ?.destinationList || ([] as iCityDetailsResult[]);

    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    setShowChatLoaderFlag(false);
    tempDestinationList = tempDestinationList.map((ele: iCityDetailsResult) => {
      if (cityDetails.id === ele.id)
        return { ...ele, checkedFlag: !ele.checkedFlag };
      return { ...ele, checkedFlag: false };
    });
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === destinationArgs.id)
          return {
            ...ele,
            destinationList: tempDestinationList,
          };
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (chatDataEle: iCityDetailsResult) => ({
                ...chatDataEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );

    if (
      tempDestinationList.some((ele: iCityDetailsResult) => ele.checkedFlag)
    ) {
      setSelectedChatData({} as iChatData);
      setShowChatLoaderFlag(true);
      scrollToBottom(desktopScrollContainerRef);
      const selectedChatData = {
        ...destinationArgs,
        destinationList: tempDestinationList,
      };
      const cityList = selectedChatData.destinationList
        .filter((city) => city.checkedFlag)
        .map((city) => city.cityName);
      setDisableInput(true);
      abortPlanTripDetails.abort();
      abortPlanTripDetails = new AbortController();
      const iteneryInput = {
        user_given_number_of_days: [
          {
            city_name: cityList[0],
            num_days: null,
          },
        ],
      };
      let response = await planTripDetails(
        abortPlanTripDetails,
        selectedChatData.prompt,
        iteneryInput
      );
      setDisableInput(false);
      response = checkIfThereIsDestination(response);
      if (
        response?.statusCode !== 200 ||
        response?.data?.cities === undefined ||
        response?.data?.cities.length === 0
      ) {
        if (!abortPlanTripDetails.signal)
          toast.error(
            getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
          );
        console.error(API_FAILURE_MESSAGE);
        return;
      }
      // const detailsResponse = response.data.details;
      const tempDestinationList_2 = selectedChatData.destinationList.map(
        (destinationListEle: iCityDetailsResult, index: number) => ({
          ...destinationListEle,
          // tripDetails: detailsResponse[index],
          complete_itinerary_flights_cost:
            response?.data?.cities[0]?.complete_itinerary_flights_cost,
          complete_itinerary_hotels_cost:
            response?.data?.cities[0]?.complete_itinerary_hotels_cost,
          tripDetails: destinationListEle.checkedFlag
            ? response.data.cities[0].tripDetails
            : [],
        })
      );
      const tempTripName = tempDestinationList_2.reduce(
        (acc: string, ele: iCityDetailsResult, index: number) => {
          if (ele.checkedFlag) {
            if (index === selectedChatData?.destinationList.length - 1)
              return acc + ele.cityName;
            else return acc + ele.cityName;
          }
          return acc;
        },
        ""
      );
      setSelectedChatData({
        ...selectedChatData,
        destinationList: tempDestinationList_2,
        tripName: tempTripName,
      });
      ReactGA.event({
        category: VIEW_ITINERARY_DETAILS.CATEGORY,
        action: VIEW_ITINERARY_DETAILS.ACTION,
        label: ` ${VIEW_ITINERARY_DETAILS.LABEL}  ${tempTripName}`,
      });
      setShowChatLoaderFlag(false);

      /**
       * MOBILE or Desktop
       */
      if (isMobile) {
        navigate(ROUTES.ItineraryDetails);
      } else {
        setScreen("singleItinerary");
      }
    }
  }

  /**
   * Side effect to cancel or abort api call
   */
  useEffect(() => {
    const ifSelected = chatData.reduce(
      (acc: number, eleChatData: iChatData) => {
        return (
          acc +
          eleChatData.destinationList.reduce(
            (subEle: number, eleDestList: iCityDetailsResult) => {
              if (eleDestList.checkedFlag) return subEle + 1;
              return subEle;
            },
            0
          )
        );
      },
      0
    );
    if (ifSelected === 0) {
      abortPlanTripDetails.abort();
    }
  }, [chatData]);

  /**
   * Side effect to save chat content to History
   */
  useEffect(() => {
    async function addHistory() {
      const historyData = {
        id: chatData[0].id,
        userId: userDetails.id,
        history: chatData,
      };
      if (userDetails?.id) {
        const addHistoryResponse = await apiFailedTryAgain(
          addHistoryAPI(historyData)
        );
        if (addHistoryResponse) {
          console.log("HISTORY IS SAVED");
        }
      }
    }
    if (callSaveHistoryAPIFlag) addHistory();
  }, [callSaveHistoryAPIFlag]);

  /**
   * Handling sending message
   */
  async function handleSendMessage() {
    setCallSaveHistoryAPIFlag(false);
    scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info("Please wait, we are still fetching the result!");
      return;
    }
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: chatInputText,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setChatInputText("");
    setDisableInput(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userDetails?.id || "",
      chatInputText,
      chatData.length > 0 ? chatData[chatData.length - 1].prompt : [],
      abortController.signal
    );
    if (!response) {
      setDisableInput(false);
      return;
    }
    response = checkIfThereIsDestination(response);
    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    processedAndGetDestinationImageURL(citiesList, response, false);
    setDisableInput(false);
    scrollToBottom(desktopScrollContainerRef);
    setCallSaveHistoryAPIFlag(true);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
  }
  async function processedAndGetDestinationImageURL(
    citiesList: iCityDetailsResult[],
    response: any,
    showMore: boolean
  ) {
    if (citiesList.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkedFlag: false,
        locations: [],
      }));
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: showMore ? prev[prev.length - 1].id : v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: showMore
            ? [...prev[prev.length - 1].destinationList, ...tempResponse]
            : tempResponse,
          itinerary_version: response?.data?.itinerary_version || 0,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName = currentItineraryList[i].cityName;
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 10) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          console.log("RESPONSE TOP Error");
        }
      }
      ReactGA.event({
        category: "Top Destination",
        action: `User has recieved the itinerary list created`,
        label: `User prompt is ${response?.data?.query}`,
      });
    } else {
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
  }

  /**
   * Handling cancelling Send message or
   * open Itinerary details or Show More
   */
  function handleCancel() {
    setShowChatLoaderFlag(false);
    setScreen("");
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    abortPlanTripDetails.abort();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setDisableInput(false);
    }
  }

  /**
   * Value of the Context
   */
  const value = {
    handleCheckboxChange,
    handleShowMore,
    handleViewSingleTripDetails,
    showChatLoaderFlag,
    navigate,
    disableInput,
    handleSendMessage,
    handleCancel,
    desktopScrollContainerRef,
  };
  return (
    <TopDestinationContext.Provider value={value}>
      {children}
    </TopDestinationContext.Provider>
  );
};

export const useTopDestinationContext = () => useContext(TopDestinationContext);

/**
 * Template 
 * 
 * import {
    createContext,
    useContext,
    ReactNode,
} from "react";
const TopDestinationContext = createContext({});
export const TopDestinationContextProvider = ({
    children
}: {
    children: ReactNode
}) => {
    const value = {
    }
    return (
        <TopDestinationContext.Provider value={value}>
            {children}
        </TopDestinationContext.Provider>
    );
};
export const useTopDestinationContext = () => useContext(TopDestinationContext);
 */
