import styles from "./upcomingTripCard.module.scss";
import { iCityDetailsResult } from "../../../types";
import { Skeleton } from "@mui/material";

export default function UpcomingTripCard({
    destinationList,
    onCardClick,
}: {
        destinationList: iCityDetailsResult[];
        onCardClick?: any
}) {
  return (
    <div className={styles.mainContainer} onClick={onCardClick}>
      {/* {destinationList.length > 1 && ( */}
      <div className={styles.tripDate}>
        <p>AUG 12 - 23, 2024</p>
      </div>
      {/* )} */}

      <div className={styles.imageContainer}>
        {destinationList[destinationList.length - 1]?.imageURL ? (
          destinationList.map((ele: iCityDetailsResult) => (
            <img
              src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = `${100 / destinationList.length}%`;
              }}
              style={{ width: `${100 / destinationList.length}%` }}
              className={styles.img}
              // src={ele.imageURL}
              alt="city Image"
            />
          ))
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>

      <div className={styles.placeHeadingContainer}>
        <div className={styles.placeHeadingMobContainer}>
          <h1 className={styles.placeHeadingMob}>
            {destinationList.reduce(
              (acc: any, ele: iCityDetailsResult, index: number) =>
                index === destinationList.length - 1
                  ? acc.substring(0, acc.length - 2) +
                    " and " +
                    ele.cityName.split(",")[0]
                  : ele.cityName.split(",")[0] + ", ",
              ""
            )}
            {/* {.cityName.split(",")[0]} */}
          </h1>

          <h2>2 adults, 2 children</h2>
        </div>

        {/* <div style={{ display: showArrowIcon ? "flex" : "none" }}>
          {selected ? (
            <img
              className={styles.selectedIcon}
              // src={selectedIcon}
              src={arrowNextIcon}
              alt="selected"
            />
          ) : (
            <img
              className={styles.nextIcon}
              src={arrowNextIcon}
              alt="arrowNextIcon"
            />
          )}
        </div> */}
      </div>
    </div>
  );
}
