import React, { ReactNode } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import CustomChip from "../../../reusableComponent/customChip/CustomChip";
import styles from "./airportSelectionPopover.module.scss";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { BookingDetails } from "../../../../types";

interface iProps {
  popoverTrigger: ReactNode;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

export default function AirportSelectionPopover({
  popoverTrigger,
  bookingDetails,
  setBookingDetails,
}: iProps) {
  const departureAirport = [
    {
      IAT: "IAD",
      airportName: "Washington Dulles International Airport",
    },
    {
      IAT: "DCA",
      airportName: "Reagan National Airport",
    },
    {
      IAT: "PHL",
      airportName: "Philadelphi Internation Airport",
    },
  ];

  const arrivalAirport = [
    {
      IAT: "IAD",
      airportName: "Washington Dulles International Airport",
    },
    {
      IAT: "DCA",
      airportName: "Reagan National Airport",
    },
    {
      IAT: "PHL",
      airportName: "Philadelphi Internation Airport",
    },
  ];

  const handleArrival = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = e.target.value;
    setBookingDetails({ ...bookingDetails, airportTo: selectedAirport });
  };

  const handleDeparture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = e.target.value;
    setBookingDetails({ ...bookingDetails, airportFrom: selectedAirport });
  };
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      popoverContent={
        <div className={styles.mainContainer}>
          <h1 className={styles.heading}>Departure Airport</h1>
          <FormControl className={styles.formControlContainer}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={departureAirport[0].IAT}
              name="radio-buttons-group"
              onChange={handleDeparture}
              >
              {departureAirport.map((ele: any) => (
                <FormControlLabel
                  value={ele.IAT}
                  control={<CustomRadio />}
                  label={
                    <p
                      className={styles.airportLable}
                      style={
                        {
                          // fontWeight: ele.airportName ===  defaultValue ? "600" : "500"
                        }
                      }>
                      {ele.IAT} - {ele.airportName}
                    </p>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>

          <h1 className={styles.heading}>Arrival Airport</h1>
          <FormControl className={styles.formControlContainer}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={arrivalAirport[0].IAT}
              name="radio-buttons-group"
              onChange={handleArrival}
              >
              {arrivalAirport.map((ele: any) => (
                <FormControlLabel
                  value={ele.IAT}
                  control={<CustomRadio />}
                  label={
                    <p
                      className={styles.airportLable}
                      style={
                        {
                          // fontWeight: ele.airportName ===  defaultValue ? "600" : "500"
                        }
                      }>
                      {ele.IAT} - {ele.airportName}
                    </p>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      }
    />
  );
}

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
