import { useContext, useEffect, useState } from "react";
import styles from "./history.module.scss";
import { getHistoryAPICall } from "../../../api/getHistory";
import toast from "react-hot-toast";
import { ROUTES, VIEW_SAVE_HISTORY } from "../../../constant";
import Loader from "../../reusableComponent/loader/Loader";
import { Stack } from "@mui/material";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { useNavigate } from "react-router-dom";
import { getConfigMessage } from "../../../utils";
import { sortDatesInAscendingOrder } from "../../../utils/dateUtils";
import ReactGA from "react-ga4";
import { APP_CONFIG } from "../../../constant/common";

function HistoryTab({
  todayHistory,
  setTodayHistory,
  yesterdayHistory,
  setYesterdayHistory,
  oneWeekAgoHistory,
  setOneWeekAgoHistory,
  lastOneMonth,
  setLastOneMonth,
  setIsOpenMobileSidebar,
  isOpenMobileSidebar = false,
}: {
  todayHistory: any;
  setTodayHistory: any;
  yesterdayHistory: any;
  setYesterdayHistory: any;
  oneWeekAgoHistory: any;
  setOneWeekAgoHistory: any;
  lastOneMonth: any;
  setLastOneMonth: any;
  setIsOpenMobileSidebar?: any;
  isOpenMobileSidebar?: boolean;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const globalContext = useContext(StateContext);
  const { userDetails, setChatData, chatData, appConfig }: iGlobalContext =
    globalContext;
  const navigate = useNavigate();
  async function getHistory() {
    setIsLoading(true);
    const response = await getHistoryAPICall(userDetails.id);
    if (response) {
      setIsLoading(false);
      console.log(response);
    }
    if (!response) {
      toast.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
    console.log(response);
    const yesterday = new Date(
      new Date().setDate(new Date().getDate() - 2)
    ).getTime();
    const now = new Date();
    const startOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    const endOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    ).getTime();

    const startOfYesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1
    ).getTime();
    const endOfYesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1,
      23,
      59,
      59,
      999
    ).getTime();

    // Calculate the start of the day one week ago
    const startOfWeekAgo = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7,
      0,
      0,
      0,
      0
    ).getTime();

    setTodayHistory(
      sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() >= startOfToday &&
            new Date(ele.createdAt).getTime() <= endOfToday
        ),
        "createdAt"
      )
    );
    setYesterdayHistory(
      sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() >= startOfYesterday &&
            new Date(ele.createdAt).getTime() <= endOfYesterday
        ),
        "createdAt"
      )
    );
    setOneWeekAgoHistory(
      sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() <= yesterday &&
            new Date(ele.createdAt).getTime() >= startOfWeekAgo
        ),
        "createdAt"
      )
    );
    setLastOneMonth(
      sortDatesInAscendingOrder(
        response.filter(
          (ele: any) => new Date(ele.createdAt).getTime() <= startOfWeekAgo
        ),
        "createdAt"
      )
    );
  }
  useEffect(() => {
    console.log("GET HIS");
    // if (isOpenMobileSidebar)
    setTimeout(() => {
      getHistory();
    }, 4000);
  }, [userDetails?.id, isOpenMobileSidebar, chatData]);

  function handleViewHistory(data: any) {
    console.log(data?.history[0]?.message);
    setChatData(data.history);
    if (setIsOpenMobileSidebar) setIsOpenMobileSidebar(false);
    navigate(ROUTES.TopDestination);
    ReactGA.event({
      category: VIEW_SAVE_HISTORY.CATEGORY,
      action: VIEW_SAVE_HISTORY.ACTION,
      label: ` ${VIEW_SAVE_HISTORY.LABEL} ${data?.history[0]?.message}`,
    });
  }

  if (userDetails.id === "") return <></>;

  if (
    isLoading &&
    todayHistory?.length === 0 &&
    yesterdayHistory?.length === 0 &&
    lastOneMonth?.length === 0
  )
    return (
      <Stack mt={2.5}>
        <Loader />
      </Stack>
    );

  return (
    <div className={styles.main}>
      {!isLoading &&
      todayHistory?.length === 0 &&
      yesterdayHistory?.length === 0 &&
      lastOneMonth?.length === 0 ? (
        <h1 className={styles.noData}>No Data</h1>
      ) : (
        <div className={styles.scrollContainer}>
          {todayHistory.length > 0 && (
            <>
              <div className={styles.historyCard}>
                <p className={styles.title}>Today</p>
                <div className={styles.todayContainer}>
                  {todayHistory.length > 0 &&
                    todayHistory.map((ele: any) => {
                      return (
                        <p
                          className={styles.desc}
                          key={ele.id}
                          onClick={() => handleViewHistory(ele)}>
                          {ele?.history[0]?.message.length > 65
                            ? ele?.history[0]?.message.substring(0, 65) + ".."
                            : ele?.history[0]?.message}
                          {/* {" >> " +
                          new Date(ele.createdAt).getDate() +
                          "-" +
                          new Date(ele.createdAt).getMonth()} */}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className={styles.divider} />
            </>
          )}

          {yesterdayHistory.length > 0 && (
            <>
              <div className={styles.historyCard}>
                <p className={styles.title}>Yesterday</p>
                <div className={styles.todayContainer}>
                  {yesterdayHistory.length > 0 &&
                    yesterdayHistory.map((ele: any) => {
                      return (
                        <p
                          className={styles.desc}
                          key={ele.id}
                          onClick={() => handleViewHistory(ele)}>
                          {ele?.history[0]?.message.length > 35
                            ? ele?.history[0]?.message.substring(0, 35) + ".."
                            : ele?.history[0]?.message}
                          {/* {" >> " +
                            new Date(ele.createdAt).getDate() +
                            "-" +
                            new Date(ele.createdAt).getMonth()} */}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className={styles.divider} />
            </>
          )}

          {oneWeekAgoHistory.length > 0 && (
            <>
              <div className={styles.historyCard}>
                <p className={styles.title}>1 week ago</p>
                <div className={styles.todayContainer}>
                  {oneWeekAgoHistory.length > 0 &&
                    oneWeekAgoHistory.map((ele: any) => {
                      return (
                        <p
                          className={styles.desc}
                          key={ele.id}
                          onClick={() => handleViewHistory(ele)}>
                          {ele?.history[0]?.message.length > 35
                            ? ele?.history[0]?.message.substring(0, 35) + ".."
                            : ele?.history[0]?.message}
                          {/* {" >> " +
                            new Date(ele.createdAt).getDate() +
                            "-" +
                            new Date(ele.createdAt).getMonth()} */}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className={styles.divider} />
            </>
          )}

          {lastOneMonth.length > 0 && (
            <>
              <div className={styles.historyCard}>
                <p className={styles.title}>Last 30 days</p>
                <div className={styles.todayContainer}>
                  {lastOneMonth.length > 0 &&
                    lastOneMonth.map((ele: any) => {
                      return (
                        <p
                          className={styles.desc}
                          key={ele.id}
                          onClick={() => handleViewHistory(ele)}>
                          {ele?.history[0]?.message.length > 35
                            ? ele?.history[0]?.message.substring(0, 35) + ".."
                            : ele?.history[0]?.message}
                          {/* {" >> " +
                            new Date(ele.createdAt).getDate() +
                            "-" +
                            new Date(ele.createdAt).getMonth()} */}
                        </p>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default HistoryTab;
