import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./customAccordion.module.scss";
import { expandIcon } from "../../../constant";

export default function CustomAccordion({
  className = "",
  summaryClass = "",
  headingComponent,
  bodyComponent,
  accordianDetailsClass = "",
  borderBottom = false,
  defaultExpanded = false,
  zeroMarginOnExpand = false,
}: {
  className?: string;
  summaryClass?: string;
  accordianDetailsClass?: string;
  headingComponent: React.ReactNode;
  bodyComponent: React.ReactNode;
  borderBottom?: boolean;
  defaultExpanded?: boolean;
  zeroMarginOnExpand?: boolean;
}) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className={`${styles.mainContainer} ${className}`}
      sx={{
        "&.Mui-expanded": {
          marginTop: `${zeroMarginOnExpand && "0"}`,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<img src={expandIcon} className={styles.expandIcon} />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${styles.summary} ${summaryClass}`}
        sx={{
          borderBottom: borderBottom ? "1px solid #cacaca" : "none",
          backgroundColor: borderBottom ? "#F4F4F4" : "transparent",
        }}
      >
        {headingComponent}
      </AccordionSummary>
      <AccordionDetails className={`${accordianDetailsClass}`}>
        {bodyComponent}
      </AccordionDetails>
    </Accordion>
  );
}
