import React, { useState } from "react";
import styles from "./imageSlider.module.scss";
import {
  destinationPng,
  swipeLeftTr,
  transparentSwipeRightIcon,
} from "../../../../constant";

interface iProps {
  className?: string;
  imagesArr: any[];
  noPaddSwipeBtn?: boolean;
}

function ImageSlider(props: iProps): JSX.Element {
  const { imagesArr = [], className, noPaddSwipeBtn = false } = props;
  const [sliderIndex, setSliderIndex] = useState(0);
  return (
    <div className={`${className} ${styles.imageContainer}`}>
      {imagesArr.length > 0 && (
        <img
          className={`${styles.rightBtn} ${styles.leftSwipeBtn} ${noPaddSwipeBtn && styles.removeLeftPadd}`}
          src={swipeLeftTr}
          alt="left swipe btn"
          onClick={() => {
            if (sliderIndex > 0) {
              setSliderIndex((prev) => prev - 1);
              return;
            }
            setSliderIndex(imagesArr.length - 1);
          }}
        />
      )}
      {imagesArr.map((eachImg, index) => {
        return (
          <img
            key={index}
            src={destinationPng}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/destinationPlaceholder.png";
              currentTarget.style.objectFit = "cover";
              currentTarget.style.height = "100%";
              currentTarget.style.width = "100%";
            }}
            alt=""
            style={{
              transform: `translateX(${(index - sliderIndex) * 100}%)`,
            }}
            className={`${styles.horizontalImages} ${noPaddSwipeBtn && styles.addExtraPadd}`}
          />
        );
      })}
      {imagesArr.length > 0 && (
        <img
          className={`${styles.rightBtn} ${noPaddSwipeBtn && styles.removeRightPadd}`}
          src={transparentSwipeRightIcon}
          alt="right btn"
          onClick={() => {
            if (sliderIndex < imagesArr.length - 1) {
              setSliderIndex((prev) => prev + 1);
              return;
            }
            setSliderIndex(0);
          }}
        />
      )}
    </div>
  );
}

export default ImageSlider;
