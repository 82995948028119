import * as React from "react";
import styles from "./cityTabs.module.scss";
import { Stack, IconButton, Button } from "@mui/material";
import { iCityDetailsResult } from "../../../types";
import Markdown from "react-markdown";
import { doneIcon, modifyEditIcon } from "../../../constant";
import CustomAccordion from "../../../component/reusableComponent/customAccordion/CustomAccordion";
import ButtonWithIcon from "../../../component/reusableComponent/buttonWithIcon/ButtonWithIcon";
import DestinationCardWithImages from "../../../component/reusableComponent/destinationCardWithImages/DestinationCardWithImages";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import EditActivityDialog from "../../../component/dialog/editAcitvityDialog/EditActivityDialog";
import DiscardChanges from "../../../component/dialog/discardChanges/discardChanges";
import Loader from "../../../component/reusableComponent/loader/Loader";

export default function CityTabs({
  itineraryVersion = 0,
  destinationList,
  handleEditActivity = () => {},
  disableInput = false,
}: {
  destinationList: iCityDetailsResult[];
  itineraryVersion?: string | number;
  handleEditActivity?: any;
  disableInput?: boolean;
}) {
  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);
  const globalContext = React.useContext(StateContext);
  const { selectedChatData, setSelectedChatData }: iGlobalContext =
    globalContext;
  function handleShowEditActivity() {
    setShowEditActivityFlag(true);
  }

  function handleEditActivityDone() {
    setShowEditActivityFlag(false);
  }

  /**
   * Edit activity Dialog
   */
  const [openEditActivityDialog, setOpenEditActivityDialog] =
    React.useState(false);
  const [editingActivityValue, setEditingActivityValue] = React.useState("");
  const [editingActivityId, setEditingActivityId] = React.useState("");
  function handleCloseEditActivityDialog() {
    setOpenEditActivityDialog(false);
    // setOpenDiscardChangesDialog(true)
  }

  function handleEditActivityLocal(data: any) {
    console.log(data);
    handleEditActivity();
    setOpenEditActivityDialog(true);
    setEditingActivityValue(data?.description);
    setEditingActivityId(data?.id);
  }

  function handleEditActivitySaveChanges() {
    // setSelectedChatData({
    //   ...selectedChatData,
    //   destinationList: selectedChatData.destinationList.map((destinationListEle: iCityDetailsResult) => ({
    //     ...destinationListEle,
    //     tripDetails: destinationListEle.tripDetails.map((tripDetailsEle: any) => ({
    //       ...tripDetailsEle,
    //       itinerary_by_time_of_the_day: tripDetailsEle.itinerary_by_time_of_the_day.map((itinerary_by_time_of_the_day_ele: any) => (
    //         itinerary_by_time_of_the_day_ele.id === editingActivityId
    //           ? {
    //             ...itinerary_by_time_of_the_day_ele,
    //             description: editingActivityValue
    //           }
    //           : itinerary_by_time_of_the_day_ele
    //       ))
    //     }))
    //   }))
    // })

    setOpenEditActivityDialog(false);
  }

  function handleEditActivityDelete() {
    // setSelectedChatData({
    //   ...selectedChatData,
    //   destinationList: selectedChatData.destinationList.map((destinationListEle: iCityDetailsResult) => ({
    //     ...destinationListEle,
    //     tripDetails: destinationListEle.tripDetails.map((tripDetailsEle: any) => ({
    //       ...tripDetailsEle,
    //       itinerary_by_time_of_the_day: tripDetailsEle.itinerary_by_time_of_the_day.map((itinerary_by_time_of_the_day_ele: any) => (
    //         itinerary_by_time_of_the_day_ele.id === editingActivityId
    //           ? {
    //             ...itinerary_by_time_of_the_day_ele,
    //             description: "Activity removed"
    //           }
    //           : itinerary_by_time_of_the_day_ele
    //       ))
    //     }))
    //   }))
    // })

    setOpenEditActivityDialog(false);
  }

  /**
   * Discard changes Dialog
   */
  const [openDiscardChangesDialog, setOpenDiscardChangesDialog] =
    React.useState(false);
  function handleCancelDicard() {
    // setOpenEditActivityDialog(true)
    // setOpenDiscardChangesDialog(false)
  }
  function handleDicard() {
    // setOpenEditActivityDialog(false)
    // setOpenDiscardChangesDialog(false)
  }

  return (
    <div className={styles.customTabPanelContainer}>
      <DiscardChanges
        open={openDiscardChangesDialog}
        onClose={handleCancelDicard}
        handleDiscard={handleDicard}
      />
      <EditActivityDialog
        open={openEditActivityDialog}
        inputValue={editingActivityValue}
        setInputValue={setEditingActivityValue}
        onClose={handleCloseEditActivityDialog}
        onSaveChanges={handleEditActivitySaveChanges}
        onDelete={handleEditActivityDelete}
      />
      <div className={styles.imageContainer}>
        <DestinationCardWithImages
          itineraryVersion={itineraryVersion}
          showArrowIcon={false}
          destinationList={destinationList}
        />
      </div>
      <p className={styles.cityDescription}>
        {destinationList[0]?.description}
      </p>

      <div className={styles.modifyButtonContainer}>
        {showEditActivityFlag ? (
          <Button
            className={styles.doneButton}
            onClick={handleEditActivityDone}
          >
            DONE
            <img src={doneIcon} alt="done" className={styles.doneIcon} />
          </Button>
        ) : (
          <ButtonWithIcon
            icon={modifyEditIcon}
            text="Modify"
            direction="row-reverse"
            onClick={handleShowEditActivity}
          />
        )}
      </div>

      <Stack mx={"-20px"}>
        {/* <pre>{JSON.stringify(destinationList[0]?.tripDetails, null, 2)}</pre> */}
        {destinationList?.map(
          (destinationEle: iCityDetailsResult, firstIndex: number) =>
            destinationEle?.tripDetails?.map((ele: any, index: number) => (
              <Stack key={index}>
                <CustomAccordion
                  defaultExpanded={index === 0 && firstIndex === 0}
                  borderBottom={true}
                  headingComponent={
                    <div className={styles.headingContainer}>
                      <div className={styles.subHeadingContainer}>
                        <h1>{ele?.day}</h1>
                        <h1>{ele?.city_name}</h1>
                      </div>
                      <h2>{ele?.day_itinerary_description}</h2>
                    </div>
                  }
                  bodyComponent={
                    <Stack pt={1} spacing={1}>
                      {ele?.itinerary_by_time_of_the_day?.map(
                        (subEle: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className={styles.activityContainer}
                            >
                              <div className={styles.timeInADay}>
                                <h1 className={styles.title}>
                                  {subEle?.time_of_the_day}
                                </h1>
                                <Markdown className={styles.description}>
                                  {subEle?.description}
                                </Markdown>
                              </div>
                              {showEditActivityFlag && (
                                <IconButton
                                  onClick={() =>
                                    handleEditActivityLocal(subEle)
                                  }
                                  className={styles.iconButton}
                                >
                                  <img
                                    className={styles.editIcon}
                                    src={modifyEditIcon}
                                    alt="edit"
                                  />
                                </IconButton>
                              )}
                            </div>
                          );
                        }
                      )}
                    </Stack>
                  }
                />
              </Stack>
            ))
        )}
      </Stack>
      {disableInput && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
}
