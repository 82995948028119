import { magnifyingGlassRegular } from "../../../constant";
import styles from "./search.module.scss";

function SearchButton(): JSX.Element {
  return (
    <div className={styles.travelCard}>
      <p className={styles.searchTitle}>s</p>
      <div className={styles.searchBtn}>
        <img
          src={magnifyingGlassRegular}
          alt="search"
          className={styles.searchIcon}
        />
      </div>
    </div>
  );
}

export default SearchButton;
