import React, { ReactNode, useEffect, useState } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./dateRangePickerPopover.module.scss";
 
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function DateRangePickerPopover({
  popoverTrigger,
  value,
  setValue,
}: {
    popoverTrigger: ReactNode;
    value: any,
    setValue: any,
}) {
  useEffect(() => {
    console.log(value);
  }, [value]);
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      popoverContent={
        <div className={styles.mainContainer}>
          <Calendar onChange={setValue} selectRange value={value} />
        </div>
      }
    />
  );
}

 
