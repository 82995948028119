import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./bookingCounter.module.scss";
import { IconButton } from "@mui/material";
import { minusIcon, plusIcon } from "../../../../../constant";

export default function BookingCounter({
  heading1,
  heading2,
  count,
  setCount,
}: {
  heading1: string;
  heading2: string;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
}) {
  function handleDecrement() {
    if (count > 0) setCount((prev) => prev - 1);
  }
  function handleIncrement() {
    setCount((prev) => prev + 1);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.counterTitleContainer}>
        <h1>{heading1}</h1>
        <p>{heading2}</p>
      </div>
      <div className={styles.counterContainer}>
        <IconButton className={styles.button} onClick={handleDecrement}>
          <img src={minusIcon} alt="minus" />
        </IconButton>
        <h1>{count}</h1>
        <IconButton className={styles.button} onClick={handleIncrement}>
          <img src={plusIcon} alt="plus" />
        </IconButton>
      </div>
    </div>
  );
}
