import React, { useCallback } from "react";
import styles from "./dialogPopup.module.scss";

interface iProps {
  className?: string;
  isOpen: boolean;
  children: JSX.Element;
}
function DialogPopup(props: iProps): JSX.Element {
  const { className, isOpen = false, children } = props;

  if (!isOpen)
    return (
      <div
        className={`${className} ${styles.modal} ${
          isOpen ? styles.isOpen : styles.hide
        }`}
        // onClick={closeModal}
      >
        <div
          className={styles.backdrop}
          onClick={(e) => {
            e.stopPropagation();
            // closeModal();
          }}
        />
        {children}
      </div>
    );
  return (
    <div
      className={`${className} ${styles.modal} ${
        isOpen ? styles.isOpen : styles.hide
      }`}
      //   onClick={closeModal}
    >
      {/* <div
        className={styles.backdrop}
        onClick={(e) => {
          e.stopPropagation();
        }}
      /> */}
      {children}
    </div>
  );
}

DialogPopup.defaultProps = {
  className: "",
};

export default DialogPopup;
