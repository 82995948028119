import { Dispatch, ReactNode, SetStateAction } from "react";
import styles from "./travelerPopover.module.scss";
import BookingCounter from "./bookingCounter/BookingCounter";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";

interface iProps {
  showRoom?: boolean;
  popoverTrigger: ReactNode;
  adultsCount: number;
  childrenCount: number;
  seniorsCount: number;
  setAdultsCount: Dispatch<SetStateAction<number>>;
  setChildrenCount: Dispatch<SetStateAction<number>>;
  setSeniorsCount: Dispatch<SetStateAction<number>>;
  numberOfRoomCount?: number;
  setNumberOfRoomCount?: Dispatch<SetStateAction<number>>;
}

export default function TravelerPopover({
  showRoom,
  popoverTrigger,
  adultsCount,
  childrenCount,
  seniorsCount,
  setAdultsCount,
  setChildrenCount,
  setSeniorsCount,
  numberOfRoomCount = 0,
  setNumberOfRoomCount,
}: iProps) {
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      popoverContent={
        <div className={styles.mainContainer}>
          <h1 className={styles.heading}>Travelers</h1>
          <BookingCounter
            count={adultsCount}
            setCount={setAdultsCount}
            heading1="Adults"
            heading2="18 - 64 years"
          />
          <BookingCounter
            count={childrenCount}
            setCount={setChildrenCount}
            heading1="Children"
            heading2="<18 years"
          />
          <BookingCounter
            count={seniorsCount}
            setCount={setSeniorsCount}
            heading1="Seniors"
            heading2="65+ years"
          />
          {showRoom && (
            <>
              <h1 className={styles.heading}>Rooms</h1>
              <BookingCounter
                count={numberOfRoomCount}
                setCount={
                  setNumberOfRoomCount ? setNumberOfRoomCount : setSeniorsCount
                }
                heading1="Number of Rooms"
                heading2="MAXIMUM 4"
              />
            </>
          )}
        </div>
      }
    />
  );
}
