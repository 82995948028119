import React from "react";
import styles from "./roomDetailsModal.module.scss";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import { Button, Stack } from "@mui/material";
import { backIcon, outlinedCircleCloseIcon } from "../../../../constant";
import ImageSlider from "../imageSlider/imageSlider";
import { ROOM_DETAILS } from "./roomDetailsConstants";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function RoomDetailsModal(props: iProps): JSX.Element {
  const { isOpen = false, onClose } = props;
  const { roomImages = [] } = ROOM_DETAILS || {};
  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={{
          minHeight: "100vh",
          mt: { sm: 0, xs: 2 },
          // justifyContent: { sm: "center", md: "center", lg: "center" },
        }}
        spacing={6}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { xs: "100vw" },
            maxWidth: { md: "450px" },
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
          >
            <Stack alignSelf="end" mb={2} mr={2.6}>
              <img
                onClick={onClose}
                style={{ width: "25px", cursor: "pointer" }}
                src={outlinedCircleCloseIcon}
                alt="cross"
              />
            </Stack>

            <Stack
              sx={{
                width: "100%",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "8px",
              }}
            >
              <Stack pt={2} pb={2} pl={0} pr={0} spacing={3}>
                <div className={styles.roomDetailsContainer}>
                  <div className={styles.closeBtnContainer}>
                    <Button
                      className={`${styles.columnAlignButton}`}
                      onClick={() => {}}
                    >
                      <img src={backIcon} alt="Close" />
                      <p>BACK</p>
                    </Button>
                  </div>
                  <div className={styles.header}>Room Details</div>
                  <ImageSlider noPaddSwipeBtn imagesArr={roomImages} />
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

export default RoomDetailsModal;
