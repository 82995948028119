import React from "react";
import { arrowIcon } from "../../../constant";
import styles from "./sidebar.module.scss";
import { SideBarData } from "../../../constant/dummyData";
import { useNavigate } from "react-router-dom";

interface iProps {
  onClick: () => void;
}

function SideBarOptions({ onClick }: iProps): JSX.Element {
  const navigate = useNavigate();

  const handleNavigate = (pathInfo: string) => {
    navigate(pathInfo);
    onClick();
  };
  return (
    <div className={styles.main}>
      {SideBarData.map((i, index) => (
        <div className={styles.container} key={index}>
          <img src={i.icon} alt="" className={styles.optionIcon} />
          <section className={styles.optionsCard}>
            <div className={styles.descCard}>
              <p className={styles.tripTitle}>{i.title}</p>
              <p className={styles.tripDesc}>{i.desc}</p>
            </div>
            <div className={styles.arrowCard}>
              <img
                src={arrowIcon}
                alt=""
                className={styles.arrowIcon}
                onClick={() => handleNavigate(i.routInfo)}
              />
            </div>
          </section>
        </div>
      ))}
    </div>
  );
}

export default SideBarOptions;
