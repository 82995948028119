import React, { ChangeEvent } from "react";
import styles from "./CustomCheckbox.module.scss";

interface CustomCheckboxProps {
  label: string;
  name: string;
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: { [key: string]: boolean };
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  name,
  handleCheckboxChange,
  selectedOptions,
}) => {
  return (
    <label className={styles.customCheckboxContainer}>
      <section className={styles.inputBox}>
        <input
          type="checkbox"
          name={name}
          checked={selectedOptions[name]}
          onChange={handleCheckboxChange}
          className={styles.customCheckbox}
        />
        <span className={styles.checkmark}></span>
      </section>
      <p className={styles.label}>{label}</p>
    </label>
  );
};

export default CustomCheckbox;
