import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function saveItineraryDetailsAPI(
  tripName: string,
  userId: string,
  metaData: any,
  numberOfDaysSelected: number,
  year: any,
  selectedMonth: any,
  is_admin_created: boolean,
) {
  const URL = `${ENDPOINT}/app/v1/users/itineraries`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    tripName,
    userId,
    metaData,
    numberOfDaysSelected,
    year,
    selectedMonth,
    is_admin_created
  };
  try {
    const response = await axios.post(URL, body, config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
