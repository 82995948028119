// import React, { useEffect, useState } from "react";
// import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
// import CustomPopover from "../../component/reusableComponent/customPopver/CustomPopover";
// import TravelerPopover from "../../component/booking/bookingPopover/travelerPopover/TravelerPopover";
// import { Button } from "@mui/material";
// import RouteClassPopover from "../../component/booking/bookingPopover/routeClassPopover/RouteClassPopover";
// import SingleDatePickerPopover from "../../component/booking/bookingPopover/singleDatePickerPopover/SingleDatePickerPopver";
// import DateRangePickerPopover from "../../component/booking/bookingPopover/dateRangePickerPopover/DateRangePickerPopover";
// import AirportSelectionPopover from "../../component/booking/bookingPopover/airportSelectionPopover/AirportSelectionPopover";

// import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import Box from "@mui/material/Box";
// import { v4 } from "uuid";
import TodayLandingPage from "../../component/home/todayLanding/todayLandingPage";

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function Test() {
  // const [singleDateValue, setSingleDateValue] = useState<Value>(new Date());
  // const [dateRangeValues, setDateRangeValues] = useState<Value>(new Date());

  // const [routeData, setRouteData] = useState({
  //   id: v4(),
  //   value: "Round Trip",
  //   checkedFlag: true,
  // });
  // const [classData, setClassData] = useState([
  //   {
  //     id: v4(),
  //     value: "Economy",
  //     checkedFlag: true,
  //   },
  //   {
  //     id: v4(),
  //     value: "Premium Plus",
  //     checkedFlag: true,
  //   },
  //   {
  //     id: v4(),
  //     value: "Business",
  //     checkedFlag: true,
  //   },
  //   {
  //     id: v4(),
  //     value: "First Class",
  //     checkedFlag: true,
  //   },
  // ]);

  // // Traveler
  // const [adultsCount, setAdultsCount] = useState(1);
  // const [childrenCount, setChildrenCount] = useState(1);
  // const [seniorsCount, setSeniorsCount] = useState(1);

  // const [numberOfRoomCount, setNumberOfRoomCount] = useState(2);

  return (
    <div>
      {/* <TravelerPopover
        adultsCount={adultsCount}
        setAdultsCount={setAdultsCount}
        childrenCount={childrenCount}
        setChildrenCount={setChildrenCount}
        seniorsCount={seniorsCount}
        setSeniorsCount={setSeniorsCount}
        numberOfRoomCount={numberOfRoomCount}
        setNumberOfRoomCount={setNumberOfRoomCount}
        popoverTrigger={<Button>With Room</Button>}
        showRoom={true}
      />
      <TravelerPopover
        adultsCount={adultsCount}
        setAdultsCount={setAdultsCount}
        childrenCount={childrenCount}
        setChildrenCount={setChildrenCount}
        seniorsCount={seniorsCount}
        setSeniorsCount={setSeniorsCount}
        popoverTrigger={<Button>Without Room</Button>}
        showRoom={false}
      />
      <RouteClassPopover
        route={routeData}
        setRoute={setRouteData}
        classData={classData}
        setClass={setClassData}
        popoverTrigger={<Button>Route Class</Button>}
      />
      <SingleDatePickerPopover
        value={singleDateValue}
        setValue={setSingleDateValue}
        popoverTrigger={<Button>Single Date Picker</Button>}
      />
      <DateRangePickerPopover
        value={dateRangeValues}
        setValue={setDateRangeValues}
        popoverTrigger={<Button>Date range Picker</Button>}
      /> */}
      {/* <AirportSelectionPopover
        popoverTrigger={<Button>Airport selection</Button>}
      /> */}
      <TodayLandingPage />
    </div>
  );
}
