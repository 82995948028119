import { Button } from "@mui/material";
import styles from "./today.module.scss";
import { TodayItineraryData } from "../../../constant/dummyData";

interface HighlightCitiesProps {
  text: string;
}

function TodayLandingPage(): JSX.Element {
  //   function formatDescription(description: string) {
  //     // Regular expression to match city names (can be customized if needed)
  //     const cityRegex = /\b([A-Z][a-z]+)\b/g;

  //     return description.replace(cityRegex, (match) => {
  //       return `<span style="color: blue;">${match}</span>`; // Wrap city names in a blue span
  //     });
  //   }

  const HighlightCities: React.FC<HighlightCitiesProps> = ({ text }) => {
    const cities = [
      "Gothic Quarter",
      "Barcelona",
      "Plaça Reial",
      "La Rambla",
      "Boqueria Market",
      "Plaça de Catalunya",
    ];

    const highlightedText = cities.reduce((acc, city) => {
      const regex = new RegExp(`\\b${city}\\b`, "g");
      return acc.replace(
        regex,
        `<span style="color: #007aff; font-weight: bold;">${city}</span>`
      );
    }, text);

    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.itineraryCard}>
          <div className={styles.dayCard}>
            <p className={styles.today}>{TodayItineraryData.day}</p>
            <p className={styles.day}>{TodayItineraryData.date}</p>
            <p className={styles.explore}>
              {" "}
              {TodayItineraryData.cityHighlights}
            </p>
          </div>
          {TodayItineraryData.dayItinerary.map((i, index) => (
            <div className={styles.dayWiseItineraryCard} key={index}>
              <p className={styles.morning}>{i.title}</p>
              <p className={styles.description}>
                {/* {i.description} */}
                <HighlightCities text={i.description} />
                {/* <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: formatDescription(i.description),
                  }}
                /> */}
                {/* <span className={styles.highlightText}>Gothic Quarter</span>,
                including the{" "}
                <span className={styles.highlightText}>
                  Barcelona Cathedral
                </span>{" "}
                and
                <span className={styles.highlightText}> Plaça Reial.</span> */}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.divider} />
        {TodayItineraryData.nearByActivity.map((i, index) => (
          <div className={styles.nearByPlaces} key={index}>
            <p className={styles.restaurants}>{i.actName}</p>
            <div className={styles.specCard}>
              <div className={styles.specs}>
                <p className={styles.specTitle}>{i.actTitle}</p>
                <p className={styles.specDesc}>{i.actDesc}</p>
              </div>
              <p className={styles.distance}>{i.distance}</p>
            </div>
          </div>
        ))}
        <div className={styles.exploreCard}>
          <Button className={styles.exploreBtn}>EXPLORE THE CITY</Button>
        </div>
      </div>
    </div>
  );
}

export default TodayLandingPage;
