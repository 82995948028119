import React, { ReactNode } from "react";
import { StateProvider } from "./globalContext/context";
import { TopDestinationContextProvider } from "./topDestinationContext/TopDestinationContext";
import { RecommendedTripContextProvider } from "./recommendedTripContext/recommendedTripContext";

export default function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <StateProvider>
      <TopDestinationContextProvider>
        <RecommendedTripContextProvider>
        {children}
        </RecommendedTripContextProvider>
      </TopDestinationContextProvider>
    </StateProvider>
  );
}
