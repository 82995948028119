import { ReactElement } from "react";
import { toast } from "react-hot-toast";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const InfoIconComponent = (): ReactElement => (
  <InfoOutlinedIcon sx={{ color: "#FFFFFF" }} />
);

const SuccessIconComponent = (): ReactElement => (
  <CheckCircleOutlineIcon sx={{ color: "#FFFFFF", width: 30, height: 30 }} />
);

const CloseIconComponent = (): ReactElement => (
  <CancelOutlinedIcon
    sx={{ color: "#FFFFFF", width: 18, height: 18, cursor: "pointer" }}
    onClick={() => toast.dismiss()}
  />
);

export const toastMessage = {
  success: (message: string, detailedMessage?: string) => {
    toast(
      <div
        style={{
          position: "relative",
          whiteSpace: "pre-line",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}>
        <div
          style={{
            fontWeight: "600",
            color: "#fffff",
            fontSize: "12px",
            letterSpacing: "0.3px",
            marginLeft: "10px",
          }}>
          {message}
        </div>
        <div
          style={{
            fontStyle: "500",
            color: "#fff",
            fontSize: "10px",
            letterSpacing: "0.2px",
            marginLeft: "10px",
          }}>
          {detailedMessage}
        </div>
      </div>,
      {
        icon: SuccessIconComponent(),
        style: {
          background: "#5B8FC8",
          color: "#FFFFFF",
          boxShadow:
            "0px 0px 0px 1.5px white, -4px 4px 10px rgba(0, 0, 0, 0.3)",
        },
      }
    );
  },
  error: (message: string, detailedMessage?: string) => {
    toast(
      <div
        style={{
          position: "relative",
          whiteSpace: "pre-line",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}>
        <div style={{ textAlign: "end", marginTop: "-10px" }}>
          <CloseIconComponent />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            marginTop: "-10px",
          }}>
          <div style={{ paddingTop: "5px", }}>
            <InfoOutlinedIcon style={{ transform: "rotate(180deg)", width: '26px', height: '26px' }} />
          </div>
          <div>
            <div
              style={{
                fontWeight: "600",
                color: "#fffff",
                fontSize: "12px",
                letterSpacing: "0.3px",
              }}>
              {message}
            </div>
            <div
              style={{
                fontStyle: "500",
                color: "#fff",
                fontSize: "10px",
                letterSpacing: "0.2px",
              }}>
              {detailedMessage}
            </div>
          </div>
        </div>
      </div>,
      {
        // icon: InfoIconComponent(),
        style: {
          background: "#D10B00",
          color: "#FFFFFF",
          boxShadow:
            "0px 0px 0px 1.5px white, -4px 4px 10px rgba(0, 0, 0, 0.3)",
        },
      }
    );
  },
  loading: (message: string) => {
    toast.loading(message);
  },
  info: (message: string, detailedMessage?: string) => {
    toast(
      <div
        style={{
          position: "relative",
          whiteSpace: "pre-line",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}>
        <div
          style={{
            fontWeight: "600",
            color: "#fffff",
            fontSize: "12px",
            letterSpacing: "0.3px",
            marginLeft: "10px",
          }}>
          {message}
        </div>
        <div
          style={{
            fontStyle: "500",
            color: "#fff",
            fontSize: "10px",
            letterSpacing: "0.2px",
            marginLeft: "10px",
          }}>
          {detailedMessage}
        </div>
      </div>,
      {
        icon: InfoIconComponent(),
        style: {
          background: "#5B8FC8",
          color: "#FFFFFF",
          boxShadow:
            "0px 0px 0px 1.5px white, -4px 4px 10px rgba(0, 0, 0, 0.3)",
        },
      }
    );
  },
};
