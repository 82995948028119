import React from "react";
import styles from "./side.module.scss";
import Profile from "../profile/profile";
import BasicTabs from "../sideBar/tabs/tabs";
import Login from "../login/login";
import MenuIcon from "../../assets/MenuIcon.svg";
import { ROUTES, updatedCloseIcon, zenvolyaNewLogo } from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import updatedNewTrip from "../../assets/updatedNewTrip.png";
import { Button } from "@mui/material";
import SideBarOptions from "./sideBarOptions/sideBarOptions";

function CustomSideBar(): JSX.Element {
  // const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState(false);
  const navigate = useNavigate();
  const state = React.useContext(StateContext);
  const {
    isOpenMobileSidebar,
    setIsOpenMobileSidebar,
    setenableMultiSelect,
    userInfo,
  }: iGlobalContext = state;
  const toggleSidebar = () => {
    setIsOpenMobileSidebar(!isOpenMobileSidebar);
  };
  const location = useLocation();

  function handleLogoClick() {
    navigate("/");
    setIsOpenMobileSidebar(false);
  }

  const getColorForPath = (path: string) => {
    switch (path) {
      case ROUTES.Home:
        return "#FAFAFA";
      case ROUTES.Booking:
        return "#FAFAFA";
      case ROUTES.NewTrip:
        return "#FAFAFA";
      case ROUTES.TopDestination:
        return "#FAFAFA";
      default:
        return "#ffff";
    }
  };
  const getBoxShadow = (path: string) => {
    switch (path) {
      case ROUTES.Home:
        return "none";
      case ROUTES.Booking:
        return "none";
      case ROUTES.NewTrip:
        return "none";
      case ROUTES.TopDestination:
        return "none";
      default:
        return "0px 3px 8px rgba(0, 0, 0, 0.24)";
    }
  };

  const showNewTrip = (path: string) => {
    switch (path) {
      case ROUTES.Home:
        return false;
      case ROUTES.Booking:
        return false;
      case ROUTES.NewTrip:
        return false;
      default:
        return true;
    }
  };

  const backgroundColor = getColorForPath(location.pathname);
  const boxShadow = getBoxShadow(location.pathname);
  const showNewTripButton = showNewTrip(location.pathname);
  return (
    <div className={styles.mainContainer}>
      <div
        className={`${styles.container} ${isOpenMobileSidebar ? styles.open : ""}`}>
        <div
          className={styles.navBar}
          style={{ backgroundColor: backgroundColor, boxShadow: boxShadow }}>
          <div className={styles.navBox}>
            <div className={styles.menuCard}>
              <img
                src={MenuIcon}
                alt=""
                className={styles.menuIcon}
                onClick={() => toggleSidebar()}
              />
              <img
                src={zenvolyaNewLogo}
                alt=""
                className={styles.logo}
                onClick={handleLogoClick}
              />
            </div>
            {showNewTripButton && (
              <Button
                onClick={() => {
                  setenableMultiSelect(false);
                  setTimeout(() => {
                    navigate(ROUTES.NewTrip);
                  }, 100);
                }}
                className={styles.button}>
                <img
                  src={updatedNewTrip}
                  alt="new trip"
                  className={styles.buttonIcon}
                />
                NEW TRIP
              </Button>
            )}
          </div>
        </div>
        <div
          className={`${styles.sidebar} ${isOpenMobileSidebar ? styles.sidebarOpen : styles.sideBarClose}`}>
          {!userInfo.loggedIn && (
            <div className={styles.closeCard}>
              <Button
                className={styles.closeButton}
                endIcon={
                  <img
                    src={updatedCloseIcon}
                    alt=""
                    className={styles.closeIcon}
                  />
                }
                onClick={() => toggleSidebar()}>
                Close
              </Button>
            </div>
          )}
          <div className={styles.box}>
            {userInfo.loggedIn === true && (
              <>
                <Profile onClick={() => toggleSidebar()} />
                <SideBarOptions onClick={() => toggleSidebar()} />
                <BasicTabs
                  setIsOpenMobileSidebar={setIsOpenMobileSidebar}
                  isOpenMobileSidebar={isOpenMobileSidebar}
                />
              </>
            )}
            {!userInfo.loggedIn && (
              <div className={styles.loginCard}>
                <Login closeDrawer={() => toggleSidebar()} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomSideBar;
