import React from "react";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { DialogSelected } from "../../constant/common";
import { logoutAPI } from "../../api/logout";
import { iGlobalContext } from "../../context/globalContext/interface";
import { LOGGED_OUT } from "../../constant";
import ReactGA from "react-ga4";
import { toastMessage } from "../../helpers/toast/toastMessage";

interface iProps {
  closeDrawer: () => void;
}

export default function Login({ closeDrawer }: iProps): JSX.Element {
  const navigate = useNavigate();
  // Context
  const state = React.useContext(StateContext);
  const {
    setNav,
    setMenuOpenState,
    userDetails,
    setLoginCreateAccountState,
    setSignInClick,
    setStartingValue,
    setDestValue,
    setClimate,
    setPopulation,
    setExperience,
    setAccomodations,
    setFoodPreferences,
    setMonths,
    setSelectedDestFlag,
    setUserDetails,
    setTripForNDays,
    setIsLoggedIn,
    setBudget,
    setScreen,
    userInfo,
    setUserInfo,
  }: iGlobalContext = state;

  // Sign in
  function handleSignIn() {
    setSignInClick(true);
    setMenuOpenState(false);
    setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
    closeDrawer();
  }
  async function handleLogoutClick() {
    const response = await logoutAPI(userDetails.id);

    if (response.statusCode === 200) {
      // Clear state
      toastMessage.success("Success", "Logout successful");
      setScreen("");
      navigate("/");
      window.localStorage.removeItem("JWT");
      const resetUserInfo = {
        id: "",
        name: "",
        email: "",
        phone: "",
        profile_pic: "",
        loggedIn: false,
        role: 0
      };

      setUserInfo(resetUserInfo);
      setUserDetails(resetUserInfo);
      setIsLoggedIn(false);

      setNav("LANDING_PAGE");
      setSelectedDestFlag(false);
      setStartingValue({ value: "", flag: false });
      setDestValue({ value: "", flag: false });

      const resetState = (
        setter: React.Dispatch<React.SetStateAction<any[]>>
      ) => setter([]);

      [
        setClimate,
        setPopulation,
        setExperience,
        setFoodPreferences,
        setAccomodations,
        setMonths,
        setBudget,
        setTripForNDays,
      ].forEach(resetState);

      closeDrawer();

      // Clear localStorage and sessionStorage
      const keysToRemove = [
        "_starting",
        "_destination",
        "_extra_meta",
        "_international",
        "_select_dest_flag",
        "shared_itinerary_id",
        "_itinerary_details_from_list",
        "_itinerary_details",
        "_shared_itinerary_id",
        "_sliderValue",
      ];
      keysToRemove.forEach((key) => {
        window.localStorage.removeItem(key);
        window.sessionStorage.removeItem(key);
      });
    }

    ReactGA.event({
      category: LOGGED_OUT.CATEGORY,
      action: LOGGED_OUT.ACTION,
      label: ` ${LOGGED_OUT.LABEL}  `,
    });
  }

  return (
    <Stack
      zIndex={999}
      bgcolor="transparent"
      sx={{ borderRadius: "4px" }}
      spacing={2}>
      {userInfo.loggedIn === true ? (
        <Button
          onClickCapture={handleLogoutClick}
          size="small"
          sx={{
            mb: { sm: 4, md: 0 },
            bgcolor: "#BF150D",
            color: "#ffff",
            borderRadius: "46.5px",
            boxShadow: "none",
            textTransform: "none",
            fontFamily: "Lato",
            pt: 0.5,
            pb: 0.5,
            pl: { xs: 2, sm: 2, md: 2 },
            pr: { xs: 2, sm: 2, md: 2 },
            "&:hover": {
              bgcolor: "#BF150D",
              color: "#ffff",
            },
          }}
          variant="text">
          Log out
        </Button>
      ) : (
        <Button
          onClickCapture={handleSignIn}
          size="small"
          sx={{
            mb: { sm: 4, md: 0 },
            bgcolor: "#BF150D",
            color: "#ffff",
            borderRadius: "46.5px",
            boxShadow: "none",
            textTransform: "none",
            fontFamily: "Lato",
            pt: 0.5,
            pb: 0.5,
            pl: { xs: 2, sm: 2, md: 2 },
            pr: { xs: 2, sm: 2, md: 2 },
            "&:hover": {
              bgcolor: "#BF150D",
              color: "#ffff",
            },
          }}
          variant="text">
          Log in
        </Button>
      )}
    </Stack>
  );
}
