// import { Popover } from "@mui/material";
// import React from "react";

// export default function CustomPopover() {
//   return (
//     <Popover
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "center",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "left",
//       }}
//     >
//       The content of the Popover.
//     </Popover>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import TravelerPopover from "../../booking/bookingPopover/travelerPopover/TravelerPopover";
import styles from "./customPopover.module.scss";
import { Button, Popover } from "@mui/material";

export default function CustomPopover({
  popoverTrigger,
  popoverContent,
}: {
  popoverTrigger?: React.ReactNode;
  popoverContent: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event:  any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div className={styles.anchorElement} aria-describedby={id} onClick={handleClick}>
        {popoverTrigger}
      </div>
      {/* <Popper id={id} open={open} anchorEl={anchorEl}> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.mainContainer}>
          {popoverContent}
          <Button className={styles.button} onClick={handleClose}>
            APPLY
          </Button>
        </div>
      </Popover>
      {/* </Popper> */}
    </div>
  );
}
