import styles from "./hotel.module.scss";

interface iProps {
  hotelRating: number;
  reviewCount: number;
  className?: string;
  ratingCardClass?: string;
  ratingClass?: string;
  contentClass?: string;
  reviewTitleClass?: string;
  reviewClass?: string;
}

function HotelRating({
  hotelRating,
  reviewCount,
  className = "",
  ratingCardClass = "",
  ratingClass = "",
  contentClass = "",
  reviewTitleClass = "",
  reviewClass = "",
}: iProps): JSX.Element {
  return (
    <div className={`${styles.main} ${className}`}>
      <div className={`${styles.ratingCard} ${ratingCardClass}`}>
        <p className={`${styles.rating} ${ratingClass}`}>{hotelRating}</p>
      </div>
      <div className={`${styles.content} ${contentClass}`}>
        <p className={`${styles.reviewTitle} ${reviewTitleClass}`}>
          Exceptional
        </p>
        <p className={`${styles.review} ${reviewClass}`}>
          {reviewCount} reviews
        </p>
      </div>
    </div>
  );
}

HotelRating.defaultProps = {
  className: "",
  ratingCardClass: "",
  ratingClass: "",
  contentClass: "",
  reviewTitleClass: "",
  reviewClass: "",
};

export default HotelRating;
