import { useState } from "react";
import AirPortPicker from "../../airPortPicker/airPortPicker";
import TravelerPopover from "../../bookingPopover/travelerPopover/TravelerPopover";
import RouteAndClass from "../../routeAndClass/routeAndClass";
import TravelPicker from "../../travellerPicker/travellerPicker";
import styles from "./addition.module.scss";
import { BookingDetails } from "../../../../types";

interface iProps {
  bookingDetails?: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

function AdditionalDetails(props: iProps): JSX.Element {
  const { bookingDetails, setBookingDetails } = props;
  const [adultsCount, setAdultsCount] = useState(1);
  const [childrenCount, setChildrenCount] = useState(1);
  const [seniorsCount, setSeniorsCount] = useState(1);

  const [numberOfRoomCount, setNumberOfRoomCount] = useState(2);
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <p className={styles.title}>Additional Details</p>
        <section className={styles.contentCard}>
          {bookingDetails && (
            <RouteAndClass
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              className={styles.routTitle}
            />
          )}
          {bookingDetails && (
            <AirPortPicker
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              className={styles.travelTitle}
            />
          )}
          {bookingDetails && (
            <TravelPicker
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              className={styles.travelTitle}
            />
          )}
          <div className={styles.travelCard}>
            <p className={styles.travelTitle}>Number of Rooms</p>

            <TravelerPopover
              adultsCount={adultsCount}
              setAdultsCount={setAdultsCount}
              childrenCount={childrenCount}
              setChildrenCount={setChildrenCount}
              seniorsCount={seniorsCount}
              setSeniorsCount={setSeniorsCount}
              numberOfRoomCount={numberOfRoomCount}
              setNumberOfRoomCount={setNumberOfRoomCount}
              popoverTrigger={
                <div className={styles.travelBtn}>
                  <p className={styles.rooms}>{numberOfRoomCount}</p>
                </div>
              }
              showRoom={true}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdditionalDetails;
