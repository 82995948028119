import React from "react";
import hotelRoom from "../../../../assets/hotelRoom.png";
import styles from "./hotel.module.scss";
import HotelRating from "../hotelRating/hotelRating";
import { Amenity } from "../../../../types";

interface iProps {
  star: number;
  hotelName: string;
  hotelLocation: string;
  hotelRating?: number;
  reviewCount?: number;
  Amenity?: Amenity[];
  hotelList: boolean;
}

function HotelCard({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  Amenity,
  hotelList,
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <img
        src={hotelRoom}
        alt="flight"
        className={`${hotelList ? styles.hotelImg : styles.packageImg}`}
      />
      <section className={styles.contentCard}>
        <span className={styles.star}>{star} star property</span>
        <p className={styles.hotelName}>{hotelName}</p>
        <p className={styles.location}>{hotelLocation}</p>
        {reviewCount !== undefined && hotelRating !== undefined && (
          <HotelRating hotelRating={hotelRating} reviewCount={reviewCount} />
        )}
        {Amenity &&
          Amenity.map((i, index) => (
            <p className={styles.amenities} key={index}>
              {i.breakFast} | {i.pool} | {i.wifi}
            </p>
          ))}
      </section>
    </div>
  );
}

export default HotelCard;
