import React from "react";
import styles from "./editActivity.module.scss";
import { Button, Dialog, Stack, TextareaAutosize } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import { toastMessage } from "../../../helpers/toast/toastMessage";

export default function EditActivityDialog({
  onClose,
  open,
  inputValue,
  setInputValue,
  onSaveChanges,
  onDelete,
}: {
  onClose?: any;
  open: boolean;
  inputValue: string;
  setInputValue: any;
  onSaveChanges?: any;
  onDelete?: any;
}) {
  function handleInputChange(e: any) {
    if (e.target.value.length > 160) {
      toastMessage.info("Text has exceed the limit of 160 character!");
      return;
    }
    setInputValue(e.target.value);
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      // open={true}
      className={styles.main}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.85)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Stack alignSelf="end" mb={2} mr={1.2}>
        <img
          onClick={onClose}
          style={{ width: "25px", cursor: "pointer" }}
          src={updateCloseIcon}
          alt="cross"
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          maxHeight: {
            sm: "80vh",
            xs: "80vh",
            md: "calc(100% - 5%)",
            lg: "calc(100% - 5%)",
          },
          overflow: "auto",
          bgcolor: "#f8f8f8",
          borderRadius: "8px",
        }}
      >
        {/* Dialog content */}
        <div className={styles.dialogContainer}>
          <div className={styles.headingContainer}>
            <h1>Edit Activity</h1>
            {/* <p>SAT, AUG 17</p> */}
          </div>
          <div className={styles.midContainer}>
            <h1>MORNING</h1>
            <TextareaAutosize
              onChange={handleInputChange}
              placeholder={""}
              value={inputValue}
              className={styles.input}
              minRows={5}
              maxRows={5}
              spellCheck="true"
            />
            <p>160</p>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={`${styles.button} ${styles.deleteBtn}`}
              onClick={onDelete}
            >
              DELETE
            </Button>
            <Button
              className={`${styles.button} ${styles.saveBtn}`}
              onClick={onSaveChanges}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </Stack>
    </Dialog>
    // <Stack
    //   zIndex={1}
    //   sx={{
    //     minHeight: "100vh",
    //     mt: { sm: 0, xs: 2 },
    //     justifyContent: { sm: "center", md: "center", lg: "center" },
    //   }}
    //   spacing={6}
    //   alignItems="center"
    // >
    //   <Stack
    //     sx={{
    //       width: { xs: "100vw" },
    //       maxWidth: { md: "350px" },
    //     }}
    //   >
    //     <Stack
    //       sx={{ width: "100%" }}
    //       px={{ xs: 3, sm: 3, md: 3, lg: 0, xl: 0 }}
    //     >
    // <Stack alignSelf="end" mb={2} mr={1.2}>
    //   <img
    //     onClick={handleCancel}
    //     style={{ width: "25px", cursor: "pointer" }}
    //     src={updateCloseIcon}
    //     alt="cross"
    //   />
    // </Stack>

    // <Stack
    //   sx={{
    //     width: "100%",
    //     maxHeight: {
    //       sm: "80vh",
    //       xs: "80vh",
    //       md: "calc(100% - 5%)",
    //       lg: "calc(100% - 5%)",
    //     },
    //     overflow: "auto",
    //     bgcolor: "#f8f8f8",
    //     borderRadius: "8px",
    //   }}
    // >
    //   {/* Dialog content */}
    //   <div className={styles.dialogContainer}>
    //     <div className={styles.headingContainer}>
    //       <h1>Edit Activity</h1>
    //       <p>SAT, AUG 17</p>
    //     </div>
    //     <div className={styles.midContainer}>
    //       <h1>MORNING</h1>
    //       <TextareaAutosize
    //         placeholder={""}
    //         value={""}
    //         className={styles.input}
    //         minRows={5}
    //         maxRows={5}
    //         spellCheck="true"
    //       />
    //       <p>160</p>
    //     </div>
    //     <div className={styles.buttonContainer}>
    //       <Button className={`${styles.button} ${styles.deleteBtn}`}>
    //         DELETE
    //       </Button>
    //       <Button className={`${styles.button} ${styles.saveBtn}`}>
    //         SAVE CHANGES
    //       </Button>
    //     </div>
    //   </div>
    // </Stack>
    //     </Stack>
    //   </Stack>
    // </Stack>
  );
}
