import { Button } from "@mui/material";
import React from "react";
import styles from "./buttonWithIcon.module.scss";

export default function ButtonWithIcon({
  onClick = () => {},
  icon,
  direction = "column",
  text,
  className = ""
}: {
  onClick?: any;
  icon: any;
  direction?: "row-reverse" | "column";
    text: string;
    className?: any
}) {
  return (
    <Button
      onClick={onClick}
      className={`${styles.button} ${className}`}
      style={{
        flexDirection: direction,
      }}
    >
      <img className={styles.iconButton} src={icon} alt={text} />
      {text}
    </Button>
  );
}
