import copyIcon from "../../assets/copyIcon.svg";
import { toastMessage } from "../../helpers/toast/toastMessage";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { FaqItem } from "../../types";
import styles from "./promopt.module.scss";

interface iProps {
  setValue: Dispatch<SetStateAction<string>>;
}

function Prompts({ setValue }: iProps): JSX.Element {
  const context = useContext(StateContext);

  const { appConfig }: iGlobalContext = context;

  const [promptData, setPromptData] = useState<FaqItem[]>([]);
  const sortedData =
    promptData && promptData?.sort((a, b) => a.key.localeCompare(b.key));
  useEffect(() => {
    const filterFaqCategory = (data: any) => {
      return data.filter(
        (item: { configCategory: string }) =>
          item.configCategory === "promptTemplate"
      );
    };

    setPromptData(filterFaqCategory(appConfig));
  }, [appConfig]);

  // const handleCopy = (title: string, description: string) => {
  //   const textToCopy = `${title} ${description}`;
  //   navigator.clipboard
  //     .writeText(textToCopy)
  //     .then(() => {
  //       toastMessage.success("Text copied to clipboard");
  //       setValue(textToCopy);
  //     })
  //     .catch((err) => {
  //       toastMessage.error("Error copying text: ", err);
  //     });
  // };
  const handleCopy = (title: string, description: string) => {
    const textToCopy = `${title} ${description}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toastMessage.success("Text copied to clipboard");
          setValue(textToCopy);
        })
        .catch((err) => {
          toastMessage.error("Error copying text: ", err);
          fallbackCopyTextToClipboard(textToCopy, setValue);
        });
    } else {
      fallbackCopyTextToClipboard(textToCopy, setValue);
    }
  };

  const fallbackCopyTextToClipboard = (
    text: string,
    setValue: (value: string) => void
  ) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      toastMessage.success("Text copied to clipboard");
      setValue(text);
    } catch (err) {
      console.error("Fallback: Error copying text: ", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <div className={styles.main}>
      {sortedData?.length > 0 && (
        <p className={styles.title}>Here are some example prompts:</p>
      )}
      <section className={styles.container}>
        {sortedData &&
          sortedData.map((i: FaqItem, index: number) => {
            const [heading, ...bodyArray] = i.value.split("\n");
            const body = bodyArray.join("\n");
            const promptTitle = heading || "";
            const promptDescription = body || "";
            return (
              <div className={styles.contentCard}>
                <div className={styles.card} key={index}>
                  <p className={styles.cartTitle}>{promptTitle}</p>
                  <div className={styles.descCard}>
                    <section className={styles.descBox}>
                      <span className={styles.desc}>{promptDescription}</span>
                    </section>
                    <img
                      src={copyIcon}
                      alt=""
                      className={styles.copyIcon}
                      onClick={() => handleCopy(promptTitle, promptDescription)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </section>
    </div>
  );
}

export default Prompts;
