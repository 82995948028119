import React, { useContext, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Stack, TextField, Button } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import fb from "../../../assets/fb.svg";
import google from "../../../assets/google.svg";
import next from "../../../assets/arrow-left-line.svg";
import { StateContext } from "../../../context/globalContext/context";
import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "@leecheuk/react-google-login";
import { checkLogin } from "../../../utils/loginAndLocalStorageutils";
import {
  APP_CONFIG,
  DialogSelected,
  emailRegex,
} from "../../../constant/common";
import { getUserDetailsApi } from "../../../api/getUserDetails";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";

export default function LoginDialog() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Context
  const state = useContext(StateContext);
  const {
    setLoginCreateAccountState,
    setUserDetails,
    loginCreateAccountState,
    setIsLoggedIn,
    signInClick,
    setSignInClick,
    setUserInfo,
    appConfig,
  }: iGlobalContext = state;
  const loginFailureMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.LOGIN)?.value ??
    "Incorrect Email or Password";
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const passwordInputRef = useRef<HTMLDivElement>(null);

  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!emailRegex.test(email)) {
        setEmailError("Enter a valid email address");
      } else {
        setEmailError("");
        passwordInputRef.current?.focus();
      }
    }
  };

  function handleCreateAccount() {
    setLoginCreateAccountState({
      ...loginCreateAccountState,
      page: DialogSelected.CREATE_ACCOUNT,
    });
  }
  function handleCancel() {
    setSignInClick(false);
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    console.log(loginCreateAccountState);
  }

  async function getUserDetails(userId: string) {
    const response = await getUserDetailsApi(userId);
    if (response) {
      setUserInfo({
        id: response.id,
        name: response.name,
        email: response.email,
        phone: response.phone,
        profile_pic: response.profile_pic,
        loggedIn: true,
        role: response?.role || 0,
      });
      setUserDetails({
        id: response.id,
        name: response.name,
        email: response.email,
        phone: response.phone,
        profile_pic: response.profile_pic,
        loggedIn: true,
        role: response?.role || 0,
      });
    }
    if (!response) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
  }

  async function handleLogin() {
    if (!emailRegex.test(email)) {
      setEmailError("Enter a valid email address");
      return;
    }
    if (password === "") {
      setPasswordError("Password field cannot be empty.");
      return;
    }
    ReactGA.event({
      category: "Button Click",
      action: `Click on log in`,
      label: `Logging in pop up dialog`,
    });
    // setUserDetails({ ...userDetails, loginType: "Email" });
    const userId = await checkLogin(
      email.toLowerCase().trim(),
      "Email",
      "",
      password,
      setUserDetails,
      setIsLoggedIn,
      signInClick,
      setLoginCreateAccountState,
      loginCreateAccountState,
      setSignInClick,
      loginFailureMessage
    );
    if (userId) getUserDetails(userId);
  }

  const handlePasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <Stack
      zIndex={1}
      sx={{
        minHeight: "100vh",
        mt: { sm: 0, xs: 2 },
        justifyContent: { sm: "center", md: "center", lg: "center" },
      }}
      spacing={6}
      alignItems="center"
    >
      <Stack
        sx={{
          width: { xs: "100vw" },
          maxWidth: { md: "350px" },
        }}
      >
        <Stack
          sx={{ width: "100%" }}
          px={{ xs: 3, sm: 3, md: 3, lg: 0, xl: 0 }}
        >
          <Stack alignSelf="end" mb={2} mr={1.2}>
            <img
              onClick={handleCancel}
              style={{ width: "25px", cursor: "pointer" }}
              src={updateCloseIcon}
              alt="cross"
            />
          </Stack>

          <Stack
            sx={{
              width: "100%",
              maxHeight: {
                sm: "80vh",
                xs: "80vh",
                md: "calc(100% - 5%)",
                lg: "calc(100% - 5%)",
              },
              overflow: "auto",
              bgcolor: "#f8f8f8",
              borderRadius: "8px",
            }}
          >
            <Stack padding={3} spacing={3}>
              <Stack
                alignItems="center"
                spacing={2}
                sx={{ pl: 2, pr: 2, pt: 2 }}
              >
                <FacebookLogin
                  appId="1477646912639104"
                  onSuccess={(response) => {
                    setUserDetails({ name: "", email: "" });
                  }}
                  onFail={(error) => {
                    console.error("Login Failed!", error);
                  }}
                  onProfileSuccess={(response: any) => {
                    login(response.email, response.name);
                    async function login(email: string, name: string) {
                      const userId = await checkLogin(
                        email.toLowerCase().trim(),
                        "Google",
                        name,
                        "",
                        setUserDetails,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                      if (userId) getUserDetails(userId);
                    }
                  }}
                  style={{
                    border: "1px solid #979797",
                    borderRadius: "4px",

                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    height={42}
                    borderRadius={1}
                    alignItems="center"
                    spacing={0}
                    bgcolor="#ffff"
                    sx={{ cursor: "pointer" }}
                  >
                    <img style={{ height: "42px" }} src={fb} alt={fb} />
                    <h3
                      style={{
                        width: "100%",
                        color: "#272727",
                        fontSize: "11px",
                        fontFamily: "Lato",
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        alignSelf: "center",
                      }}
                    >
                      LOG IN WITH FACEBOOK
                    </h3>
                  </Stack>
                </FacebookLogin>

                <GoogleLogin
                  clientId="643694378557-60uta6pjlhds86si515v007r4ecuulrg.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      style={{
                        border: "1px solid #979797",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <Stack
                        direction="row"
                        height={42}
                        borderRadius={1}
                        alignItems="center"
                        spacing={0}
                        bgcolor=" #ffff"
                        sx={{ cursor: "pointer" }}
                      >
                        <img
                          style={{ height: "42px" }}
                          src={google}
                          alt={google}
                        />
                        <h3
                          style={{
                            marginLeft: "-14px",
                            color: "#272727",
                            fontSize: "11px",
                            fontFamily: "Lato",
                            letterSpacing: "0.5px",
                            width: "100%",
                            textAlign: "center",
                            alignSelf: "center",
                          }}
                        >
                          LOG IN WITH GOOGLE
                        </h3>
                      </Stack>
                    </button>
                  )}
                  onSuccess={(response: any) => {
                    const name =
                      response.profileObj.givenName +
                      " " +
                      response.profileObj.familyName;
                    const email = response.profileObj.email;
                    login();
                    async function login() {
                      const userId = await checkLogin(
                        email.toLowerCase().trim(),
                        "Google",
                        name,
                        "",
                        setUserDetails,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                      if (userId) getUserDetails(userId);
                    }
                    setLoginCreateAccountState({
                      ...loginCreateAccountState,
                      flag: false,
                    });
                  }}
                  onFailure={(error: any) => {
                    console.log(error);
                  }}
                  cookiePolicy={"single_host_origin"}
                  style={{
                    width: "100%",
                    visibility: "hidden",
                    backgroundColor: "black",
                  }}
                ></GoogleLogin>
              </Stack>

              <Stack alignSelf="center">
                <h5
                  style={{
                    fontFamily: "Lato",
                    color: "#272727",
                    fontWeight: "600",
                    fontSize: "11px",
                    letterSpacing: "0.9px",
                  }}
                >
                  OR
                </h5>
              </Stack>

              <Stack spacing={2} sx={{ width: "100%", pl: 2, pr: 2 }}>
                <Stack
                  position="relative"
                  alignSelf="start"
                  sx={{ width: "100%" }}
                >
                  <h2
                    style={{
                      fontSize: "10px",
                      marginBottom: "6px",
                      color: "#5F6A70",
                      fontWeight: "500",
                      fontFamily: "Lato",
                      opacity: "0.9",
                    }}
                  >
                    LOG IN WITH YOUR EMAIL
                  </h2>
                  <TextField
                    type="text"
                    size="small"
                    placeholder="enter your email"
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onKeyDown={handleEmailKeyDown}
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Stack>
                <Stack
                  position="relative"
                  alignSelf="start"
                  sx={{ width: "100%" }}
                >
                  <h2
                    style={{
                      fontSize: "10px",
                      marginBottom: "6px",
                      color: "#5F6A70",
                      fontWeight: "500",
                      fontFamily: "lato",
                      letterSpacing: "0.1px",
                      opacity: "0.9",
                    }}
                  >
                    PASSWORD
                  </h2>
                  <TextField
                    inputRef={passwordInputRef}
                    type="password"
                    size="small"
                    placeholder="enter password"
                    value={password}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onKeyDown={handlePasswordKeyDown}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Stack>
              </Stack>

              <Stack spacing={2} sx={{ width: "100%", pl: 2, pr: 2 }}>
                <h6 style={{ color: "#8F8F8F", fontFamily: "Lato" }}>
                  Forgot your password?{" "}
                  <b
                    onClick={() => {
                      setLoginCreateAccountState({
                        ...loginCreateAccountState,
                        page: DialogSelected.FORGET_PASS,
                      });
                    }}
                    style={{
                      color: "#27A9E0",
                      cursor: "pointer",
                      fontWeight: "600",
                      fontFamily: "Lato",
                    }}
                  >
                    Tap here
                  </b>{" "}
                  for help.
                </h6>
                <h6 style={{ color: "#8F8F8F", fontFamily: "Lato" }}>
                  Don't have an account?{" "}
                  <b
                    onClick={handleCreateAccount}
                    style={{ color: "#27A9E0", cursor: "pointer" }}
                  >
                    Create one now!
                  </b>
                </h6>
              </Stack>
            </Stack>

            <Button
              variant="contained"
              endIcon={<img src={next} alt="next" />}
              sx={{
                height: 80,
                bgcolor: "#FCB400",
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                outline: "none",
                "&:hover": {
                  bgcolor: "#FCB400",
                },
              }}
              onClick={handleLogin}
            >
              <h5
                style={{
                  color: "#222222",
                  fontWeight: "600",
                  fontSize: "18px",
                  fontFamily: "lato",
                }}
              >
                LOG IN
              </h5>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
