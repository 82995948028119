import React from "react";
import styles from "./details.module.scss";
import { expandIcon } from "../../../../constant";

interface iProps {
  onClick: () => void;
}

function HotelDetailsHeader({ onClick }: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <section className={styles.card1}>
          <p className={styles.detail}>Details</p>
        </section>
        <section className={styles.card2}>
          <p className={styles.travelerInfo}>
            1 adult, 2 children
            <br />1 Room
          </p>
        </section>
        <section className={styles.card3}>
          <p className={styles.flightDetails}>
            Barcelona
            <br />
            Dec 18 - Dec 24, 2024
          </p>
        </section>
        <section className={styles.card4}>
          <img
            src={expandIcon}
            alt=""
            className={styles.expandIcon}
            onClick={onClick}
          />
        </section>
      </div>
    </div>
  );
}

export default HotelDetailsHeader;
