import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
} from "@mui/material";
import britishAirways from "../../../assets/britishAirways.png";
import styles from "./package.module.scss";
import { bedWhite, expandIcon, planeEnginesWhite } from "../../../constant";
import HotelCard from "../hotelBooking/hotelCard/hotelCard";
import { useState } from "react";

interface iProps {
  startTime: string;
  endTime: string;
  flightName: string;
  stopsInfo: string;
  stopTime: string;
  flightCost: string;
  flightClass: string;
  star: number;
  hotelName: string;
  hotelLocation: string;
  roomType: string;
}

const FlightInfo = ({
  startTime,
  endTime,
  flightName,
  stopsInfo,
  stopTime,
}: Pick<
  iProps,
  "startTime" | "endTime" | "flightName" | "stopsInfo" | "stopTime"
>) => (
  <div className={styles.card1}>
    <img src={britishAirways} alt="flight" className={styles.flightImage} />
    <div className={styles.flightInfo}>
      <p className={styles.flightTime}>
        {startTime} - {endTime}
        <sup className={styles.supText}>+ 1</sup>
        <br />
        <span className={styles.flightName}>{flightName}</span>
      </p>
      <p className={styles.stops}>
        {stopsInfo}
        <br />
        {stopTime}
      </p>
    </div>
  </div>
);

const FlightDetailsButton = () => (
  <Button
    className={styles.flightBtn}
    endIcon={<img src={expandIcon} alt="icon" className={styles.expIcon} />}>
    Flight
    <br />
    Details
  </Button>
);

const FlightCostRadio = ({
  flightCost,
  selectedFlightCost,
  onChange,
}: {
  flightCost: string;
  selectedFlightCost: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <FormControl className={styles.radioControl}>
    <RadioGroup
      value={selectedFlightCost}
      onChange={onChange}
      className={styles.radioGroup}>
      <FormControlLabel
        value={flightCost}
        control={<CustomRadio />}
        label={flightCost}
        className={styles.radio}
      />
    </RadioGroup>
  </FormControl>
);

const PlaneBedCard = ({
  flightClass,
  roomType,
}: Pick<iProps, "flightClass" | "roomType">) => (
  <div className={styles.planeBedCard}>
    <div className={styles.planeBed}>
      <img src={planeEnginesWhite} alt="logo" className={styles.plane} />
      <p className={styles.planDesc}>{flightClass}</p>
    </div>
    <div className={styles.planeBed}>
      <img src={bedWhite} alt="logo" className={styles.plane} />
      <p className={styles.planDesc}>{roomType}</p>
    </div>
  </div>
);

function PackageBooking({
  startTime,
  endTime,
  flightName,
  stopsInfo,
  stopTime,
  flightCost,
  flightClass,
  star,
  hotelName,
  hotelLocation,
  roomType,
}: iProps): JSX.Element {
  const [selectedFlightCost, setSelectedFlightCost] = useState<string>("");

  const handleFlightCostChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedFlightCost(event.target.value);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.contentCard}>
          <div className={styles.hotelListCard}>
            <div className={styles.hotelFlight}>
              <FlightInfo
                startTime={startTime}
                endTime={endTime}
                flightName={flightName}
                stopsInfo={stopsInfo}
                stopTime={stopTime}
              />
              <HotelCard
                star={star}
                hotelName={hotelName}
                hotelLocation={hotelLocation}
                hotelList={false}
              />
            </div>
            <div className={styles.card3}>
              <FlightDetailsButton />
            </div>
            <div className={styles.card4}>
              <div className={styles.radioBtn}>
                <FlightCostRadio
                  flightCost={flightCost}
                  selectedFlightCost={selectedFlightCost}
                  onChange={handleFlightCostChange}
                />
              </div>
              <PlaneBedCard flightClass={flightClass} roomType={roomType} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageBooking;

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon}>
        <span className={styles.selected} />
      </span>}
    />
  );
}
