import React, { useRef, useState } from "react";
import {
  accomodationsData,
  foodPreferencesData,
} from "../../utils/initialData";
import { iGlobalContext } from "./interface";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { HomePageNav } from "../../constant/common";
import { iItineraryDetailsData } from "../../interface/common";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  iAppConfig,
  iChatData,
  iselectedChatData,
  UserInfo,
} from "../../types";

export const StateContext = React.createContext({} as iGlobalContext);

export function StateProvider({ children }: any) {
  const [openOverlayScreen, setOpenOverlayScreen] = useSessionStorage(
    "_trip_trove_open_overlay",
    false
  );
  const [showMoreFlag, setShowMoreFlag] = useState(false);
  const [nav, setNav] = useSessionStorage(
    "_trip_trove_nav",
    HomePageNav.LANDING_PAGE
  );
  // ZIPCODE
  const [zip, setZip] = useState<number>();
  // header background
  const [headerBackground, setHeaderBackground] = useState("transparent");
  interface ObjType {
    id: number;
    data: string;
    check: boolean;
  }
  interface MonthsType {
    id: number;
    data: string;
    numOfDays: string;
    year: string;
    check: boolean;
  }

  const [climate, setClimate] = useSessionStorage(
    "_trip_trove_climate",
    [] as ObjType[]
  );
  const [population, setPopulation] = useSessionStorage(
    "_trip_trove_population",
    [] as ObjType[]
  );
  const [experience, setExperience] = useSessionStorage(
    "_trip_trove_experience",
    [] as ObjType[]
  );
  const [accomodations, setAccomodations] = useSessionStorage(
    "_trip_trove_accomodation",
    accomodationsData
  );
  const [foodPreferences, setFoodPreferences] = useSessionStorage(
    "_trip_trove_food_preferences",
    foodPreferencesData
  );
  const [months, setMonths] = useSessionStorage(
    "_trip_trove_months",
    [] as MonthsType[]
  );
  const [budget, setBudget] = useSessionStorage(
    "_trip_trove_budget",
    [] as any[]
  );
  const [userLocation, setUserLocation] = useState("");
  // Onloading of Itienerary details state
  const [itineraryDetailsLoad, setItineraryDetailsLoad] = useState(false);
  // Staring and Dest State
  const [startingValue, setStartingValue] = React.useState({
    value: "",
    flag: false,
  });
  const [destValue, setDestValue] = React.useState({ value: "", flag: false });
  // User Location state
  const [latLong, setLatLong] = useState({
    lat: "",
    long: "",
  });
  // API Response Data
  const [apiResponse, setApiResponse] = useSessionStorage(
    "_trip_trove_api_response",
    []
  );
  const [singleItineraryDetails, setSingleItineraryDetails] = useSessionStorage(
    "_trip_trove_single_itinerary_details",
    {}
  );
  const [data, setData] = useState("");
  // First two category Display state
  const [firstTwoCategoryState, setFirstTwoCategoryState] =
    React.useState(false);
  // Destination selected Flag
  const [selectedDestFlag, setSelectedDestFlag] = useSessionStorage(
    "_trip_trove_select_destination_flag",
    false
  );
  // Loading Screen
  const [loadingScreenState, setLoadingScreenState] = React.useState(false);
  // Selected Budget and Duration Flag
  const [budgetDurationFlag, setBudgetDurationFlag] = useState(false);
  // Result test State
  const [test, setTest] = useState(false);
  // Menu State
  const [menuOpenState, setMenuOpenState] = React.useState(false);
  // Log in in Createa account
  const [loginCreateAccountState, setLoginCreateAccountState] = useState({
    flag: false,
    page: "",
  });
  const [saveScreenState, setSaveScreenState] = React.useState(false);
  // Edit category flag
  const [editState, setEditState] = React.useState(false);
  // IS logged in state
  const [isLogin, setIsLogin] = useState(false);
  // Edit account
  const [prevNav, setPrevNav] = useSessionStorage("_trip_trove_prev_nav", "");
  // Edit account pre Route
  const [prevRoute, setPrevRoute] = useState("");
  // Saved Itineraries List
  const [savedItinerariesList, setSavedItinerariesList] = useState([]);
  // Itinerary Individual Details
  const [itinerayDetails, setItineraryDetails] = useState({});
  // User Details
  const [userDetails, setUserDetails] = useLocalStorage(
    "_trip_trove_user_details",
    {
      id: "",
      name: "",
      email: "",
      loginType: "",
      profile_pic: "",
      displayQuery: false,
      phone: "",
      loggedIn: false,
    }
  );
  // Starting and Destination country
  const [startDestCountry, setStartDestCountry] = React.useState({
    start: "",
    dest: "",
  });
  // Number of Days Slider
  const [sliderValue, setSliderValue] = React.useState(3);
  // Log in flag
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [internationDest, setInternationalDest] = React.useState(false);
  //click on signin button from menu state
  const [signInClick, setSignInClick] = useState(false);
  // Trip with N number of days
  const [tripForNDays, setTripForNDays] = useSessionStorage(
    "_trip_trove_trip_for_N_days",
    []
  );
  // Add more id
  const [addMoreId, setAddMoreId] = useState("");
  // Plan  Trip message
  const [fetchingPlanTripMessage, setFetchingPlanTripMessage] = React.useState(
    "Creating trip plan..."
  );
  // Reset Passsword Token
  const [resetPasswordToken, setResetPasswordToken] = React.useState("");
  // shared Itinerary ID
  const [sharedItineraryId, setSharedItineraryId] = useSessionStorage(
    "_trip_trove_shared_itinerary_id",
    ""
  );
  // Abort Get trip plan
  const [abortTripAPICall, setAbortTripAPICall] = React.useState(false);
  // Plan trip stream Data
  const [dataStream, setDataStream] = React.useState("");
  const [tripPlanDataStream, setTripPlanDataStream] = React.useState<any>([]);
  // Extra Metadata
  const [extraMeta, setExtraMeta] = useSessionStorage(
    "_trip_trove_extra_meta",
    {
      orginatedCity: "",
      destinationCity: "",
      selectedMonth: "",
      year: "",
      internationalFlag: false,
      numberOfDaysSelected: 2,
      numberOfExperienceSelected: "",
      planTripArray: [],
    }
  );
  //View or edit state
  const [editViewState, setEditViewState] = React.useState(false);
  // State to add a Add more button in experience
  const [moreButton, setMoreButton] = useState(false);
  const [expHeight, setExpHeight] = useState(0);
  // Query State
  const [query, setQuery] = useSessionStorage("_trip_trove_query", {
    listingQuery: "",
    planTripQuery: "",
    updateTripQuery: "",
  });
  const [screen, setScreen] = useSessionStorage("screen_details", "");
  const [profile, setProfile] = useSessionStorage("userInfo", "");
  // Deelinking Data for Airport Codes and dates
  const [deepLinkData, setDeepLinkData] = React.useState({
    start: "",
    end: "",
    currentDate: "",
  });
  const [metaData, setMetaData] = useSessionStorage(
    "_trip_trove_itinerary_details",
    []
  );
  const [shareClick, setShareClick] = useSessionStorage(
    "_trip_trove_share_click",
    false
  );
  /**
   * Edting Flag for Experience
   */
  const [editExperienceFlag, setEditExperienceFlag] = useSessionStorage(
    "_trip_trove_edit_experience_flag",
    false
  );
  const [editClimatePopulationFlag, setEditClimatePopulationFlag] =
    useSessionStorage("_trip_trove_edit_climate_population_flag", false);

  const [isAdmin, setIsAdmin] = useSessionStorage("_trip_trove_is_admin", true);
  const [isMobile, setIsMobile] = useState(false);

  // Chat input text
  const [chatInputText, setChatInputText] = useSessionStorage(
    "_trip_trove_chat_input_text",
    ""
  );

  // Itinerary details data
  const [itineraryDetailsData, setItineraryDetailsData] = useSessionStorage(
    "_trip_trove_itinerary_details",
    [] as iItineraryDetailsData[]
  );

  const [showMoreReqBody, setShowMoreReqBody] = useSessionStorage(
    "_trip_trove_show_more_req_body",
    []
  );

  const [chatData, setChatData] = useSessionStorage(
    "_trip_trove_chat_data",
    [] as iChatData[]
  );
  const [selectedChatData, setSelectedChatData] = useSessionStorage(
    "_trip_trove_selected_chat_data",
    {} as iChatData
  );

  const [enableMultiSelect, setenableMultiSelect] = useSessionStorage(
    "_trip_trove_enable_multi_select",
    false
  );

  const [openDetailsSliderFlag, setOpenDetailsSliderFlag] = useSessionStorage(
    "_trip_trove_open_details_slider_flag",
    false
  );

  const [isItinerarySavedFlag, setIsItinerarySavedFlag] = useSessionStorage(
    "_trip_trove_is_itinerary_saved_flag",
    false
  );

  const [userInfo, setUserInfo] = useLocalStorage<UserInfo>("user_info", {
    id: "",
    name: "",
    email: "",
    phone: "",
    profile_pic: "",
    loggedIn: false,
    role: 0
  });

  const scrollContainerRef: any = useRef(null);

  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState(false);

  const [callSaveHistoryAPIFlag, setCallSaveHistoryAPIFlag] = useState(false);

  const [appConfig, setAppConfig] = useLocalStorage(
    "_trip_trove_app_config",
    [] as iAppConfig[]
  );

  const [modifyItineraryPrompt, setModifyItineraryPrompt] = useSessionStorage(
    "_trip_trove_modify_itinerary_prompt",
    ""
  );

  const [trigerredListItineraryAPIFlag, setTrigerredListItineraryAPIFlag] =
    useState(false);

  return (
    <StateContext.Provider
      value={{
        openOverlayScreen,
        setOpenOverlayScreen,
        deepLinkData,
        setDeepLinkData,
        tripPlanDataStream,
        setTripPlanDataStream,
        query,
        setQuery,
        dataStream,
        setDataStream,
        startDestCountry,
        setStartDestCountry,
        abortTripAPICall,
        setAbortTripAPICall,
        startingValue,
        setStartingValue,
        destValue,
        setDestValue,
        userLocation,
        setUserLocation,
        nav,
        setNav,
        climate,
        setClimate,
        population,
        setPopulation,
        experience,
        setExperience,
        data,
        setData,
        latLong,
        setLatLong,
        apiResponse,
        setApiResponse,
        headerBackground,
        setHeaderBackground,
        zip,
        setZip,
        firstTwoCategoryState,
        setFirstTwoCategoryState,
        selectedDestFlag,
        setSelectedDestFlag,
        accomodations,
        setAccomodations,
        foodPreferences,
        setFoodPreferences,
        months,
        setMonths,
        budget,
        setBudget,
        loadingScreenState,
        setLoadingScreenState,
        budgetDurationFlag,
        setBudgetDurationFlag,
        menuOpenState,
        setMenuOpenState,
        loginCreateAccountState,
        setLoginCreateAccountState,
        saveScreenState,
        setSaveScreenState,
        test,
        setTest,
        editState,
        setEditState,
        isLogin,
        setIsLogin,
        userDetails,
        setUserDetails,
        prevNav,
        setPrevNav,
        internationDest,
        setInternationalDest,
        prevRoute,
        setPrevRoute,
        isLoggedIn,
        setIsLoggedIn,
        sliderValue,
        setSliderValue,
        signInClick,
        setSignInClick,
        savedItinerariesList,
        setSavedItinerariesList,
        itinerayDetails,
        setItineraryDetails,
        tripForNDays,
        setTripForNDays,
        addMoreId,
        setAddMoreId,
        moreButton,
        setMoreButton,
        expHeight,
        setExpHeight,
        singleItineraryDetails,
        setSingleItineraryDetails,
        fetchingPlanTripMessage,
        setFetchingPlanTripMessage,
        resetPasswordToken,
        setResetPasswordToken,
        extraMeta,
        setExtraMeta,
        editViewState,
        setEditViewState,
        sharedItineraryId,
        setSharedItineraryId,
        itineraryDetailsLoad,
        setItineraryDetailsLoad,
        showMoreFlag,
        setShowMoreFlag,
        metaData,
        setMetaData,
        shareClick,
        setShareClick,
        editExperienceFlag,
        setEditExperienceFlag,
        editClimatePopulationFlag,
        setEditClimatePopulationFlag,
        isAdmin,
        setIsAdmin,
        isMobile,
        setIsMobile,
        chatInputText,
        setChatInputText,
        itineraryDetailsData,
        setItineraryDetailsData,
        showMoreReqBody,
        setShowMoreReqBody,
        chatData,
        setChatData,
        selectedChatData,
        setSelectedChatData,
        enableMultiSelect,
        setenableMultiSelect,

        openDetailsSliderFlag,
        setOpenDetailsSliderFlag,
        isItinerarySavedFlag,
        setIsItinerarySavedFlag,
        scrollContainerRef,
        screen,
        setScreen,
        profile,
        setProfile,
        userInfo,
        setUserInfo,

        isOpenMobileSidebar,
        setIsOpenMobileSidebar,
        callSaveHistoryAPIFlag,
        setCallSaveHistoryAPIFlag,
        appConfig,
        setAppConfig,
        modifyItineraryPrompt,
        setModifyItineraryPrompt,
        trigerredListItineraryAPIFlag,
        setTrigerredListItineraryAPIFlag,
      }}>
      {children}
    </StateContext.Provider>
  );
}
