import React, { useState } from "react";
import styles from "./hotelDetails.module.scss";
import { Button } from "@mui/material";
import { backIcon, propertyStarBlue } from "../../constant";
import { HOTEL_DETAILS } from "./hotelDetailsConstants";
import ImageSlider from "./components/imageSlider/imageSlider";
import HotelRating from "../booking/hotelBooking/hotelRating/hotelRating";
import CustomAccordion from "../reusableComponent/customAccordion/CustomAccordion";
import Amenities from "./components/amenities/amenities";
import AboutProperty from "./components/aboutProperty/aboutProperty";
import RoomDetailsModal from "./components/roomDetailsModal/roomDetailsModal";

function HotelDetails(): JSX.Element {
  const {
    hotelImages,
    hotelStar,
    hotelName,
    place,
    address,
    ratings,
    reviews,
    landMark,
    amenities = [],
    aboutProperty = [],
  } = HOTEL_DETAILS;
  const [showRoomDetails, setShowRoomDetails] = useState(false);
  return (
    <div className={styles.hotelDetailsContainer}>
      <div className={styles.closeBtnContainer}>
        <Button className={`${styles.columnAlignButton}`} onClick={() => {}}>
          <img src={backIcon} alt="Close" />
          <p>BACK</p>
        </Button>
      </div>
      <div className={styles.horizontalScrollContainer}>
        <ImageSlider imagesArr={hotelImages} />
      </div>
      <div className={styles.hotelDescriptionContainer}>
        <section className={styles.contentCard}>
          <div className={styles.starContainer}>
            <img
              className={styles.starPropertyImg}
              src={propertyStarBlue}
              alt="star icon"
            />
            <span className={styles.star}>{hotelStar} star property</span>
          </div>
          <p
            className={styles.hotelName}
            onClick={() => setShowRoomDetails(true)}
          >
            {hotelName}
          </p>
          <p className={styles.location}>
            {place} | {address}
          </p>
          <p className={styles.landMark}>{landMark}</p>
          <HotelRating
            className={styles.ratingsSection}
            ratingCardClass={styles.ratingCardClass}
            ratingClass={styles.ratingClass}
            hotelRating={ratings}
            reviewCount={reviews}
            contentClass={styles.contentClass}
            reviewClass={styles.reviewClass}
            reviewTitleClass={styles.reviewTitleClass}
          />
        </section>
      </div>
      <CustomAccordion
        className={styles.customStyles}
        summaryClass={styles.summaryClass}
        accordianDetailsClass={styles.accordianDetailsClass}
        defaultExpanded={false}
        headingComponent={<span className={styles.heading}>Amenities</span>}
        bodyComponent={<Amenities aminitiesArr={amenities} />}
        zeroMarginOnExpand
        borderBottom
      />
      <CustomAccordion
        className={styles.customStyles}
        summaryClass={styles.summaryClass}
        defaultExpanded={false}
        headingComponent={
          <span className={styles.heading}>About the Property</span>
        }
        bodyComponent={<AboutProperty aboutPropertyArr={aboutProperty} />}
        zeroMarginOnExpand
        borderBottom
      />
      <RoomDetailsModal
        isOpen={showRoomDetails}
        onClose={() => {
          setShowRoomDetails(false);
        }}
      />
    </div>
  );
}

export default HotelDetails;
