import React from "react";
import styles from "./detail.module.scss";
import { magnifyingGlassRegular, updatedCloseIcon } from "../../../../constant";
import TravelPicker from "../../travellerPicker/travellerPicker";
import DatePicker from "../../datePicker/datePicker";
import AirPortPicker from "../../airPortPicker/airPortPicker";
import RouteAndClass from "../../routeAndClass/routeAndClass";
import { BookingDetails } from "../../../../types";

interface iProps {
  onClick: () => void;
  bookingDetails?: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

function UserDetails({
  bookingDetails,
  onClick,
  setBookingDetails,
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.closeCard}>
          <img
            src={updatedCloseIcon}
            alt=""
            className={styles.closeIcon}
            onClick={onClick}
          />
        </div>

        <section className={styles.detailCard}>
          <p className={styles.title}>Details</p>
          <div className={styles.inputCard}>
            <div className={styles.inputBox}>
              {bookingDetails && (
                <TravelPicker
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                />
              )}

              {bookingDetails && (
                <AirPortPicker
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                />
              )}
              <DatePicker
                title="Travel Dates"
                startDate="Fri, DEC 18"
                endDate="Wed, DEC 23"
              />
              {bookingDetails && (
                <RouteAndClass
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                />
              )}
              <div className={styles.travelCard}>
                <p className={styles.searchTitle}>s</p>
                <div className={styles.searchBtn}>
                  <img
                    src={magnifyingGlassRegular}
                    alt="search"
                    className={styles.searchIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UserDetails;
