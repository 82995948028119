import { useContext } from "react";
import { zenvolyaNewLogo, ROUTES } from "../../../constant";
import styles from "./nav.module.scss";
import { useNavigate } from "react-router-dom";
import ProfileMenu from "../avatar/avatar";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import Login from "../../login/login";

function Navbar(): JSX.Element {
  const navigate = useNavigate();
  const context = useContext(StateContext);
  const { setScreen, userInfo }: iGlobalContext = context;
  const handleLogoClick = () => {
    navigate(ROUTES.Home);
    setScreen("");
  };

  return (
    <div className={styles.main}>
      <img
        className={styles.logo}
        alt="logo"
        src={zenvolyaNewLogo}
        onClick={handleLogoClick}
      />
      {userInfo.loggedIn === true ? (
        <ProfileMenu />
      ) : (
        <Login closeDrawer={() => {}} />
      )}
    </div>
  );
}

export default Navbar;
