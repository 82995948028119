import britishAirways from "../../../../assets/britishAirways.png";
import styles from "./flight.module.scss";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { expandIcon } from "../../../../constant";
import { CustomRadio } from "../../customRadio/customRadio";

interface iProps {
  startTime: string;
  endTime: string;
  flightName: string;
  stopsInfo: string;
  stops: string;
  stopTime: string;
  airports: string;
  flightCost: string;
  flightClass: string;
  tourPackage: boolean;
}

const FlightListing: React.FC<iProps> = ({
  startTime,
  endTime,
  flightName,
  stopsInfo,
  stops,
  stopTime,
  airports,
  flightCost,
  flightClass,
  tourPackage,
}) => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.contentCard}>
          <div className={styles.hotelListCard}>
            <div className={styles.card1}>
              <img
                src={britishAirways}
                alt="flight"
                className={styles.flightImage}
              />
              <p className={styles.flightTime}>
                {startTime} - {endTime}
                <sup className={styles.supText}>+ 1</sup>
                <br />
                <span className={styles.flightName}>{flightName}</span>
              </p>
            </div>
            <div className={styles.card2}>
              <p className={styles.stops}>
                {stopsInfo}
                <br />
                <span className={styles.stopInfo}>{stops}</span>
              </p>
              <p className={styles.stops}>
                {stopTime}
                <br />
                <span className={styles.stopInfo}>{airports}</span>
              </p>
            </div>
            <div className={styles.card3}>
              <Button
                className={styles.flightBtn}
                endIcon={
                  <img src={expandIcon} alt="icon" className={styles.expIcon} />
                }>
                Flight
                <br />
                Details
              </Button>
            </div>
            <div className={styles.card4}>
              {tourPackage ? (
                <section className={styles.tourPack}>
                  <FormControl className={styles.radioControl}>
                    <RadioGroup
                      defaultValue=""
                      name={flightCost}
                      className={styles.radioGroup}>
                      <FormControlLabel
                        value={flightCost}
                        control={<CustomRadio />}
                        label={
                          <span className={styles.radio}>
                            ${flightCost}
                            <span className={styles.flightClass}>
                              {flightClass}
                            </span>
                          </span>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </section>
              ) : (
                <section className={styles.flight}>
                  <p className={styles.price}>
                    ${flightCost}
                    <br />
                    <span className={styles.flightClass}>{flightClass}</span>
                  </p>
                  <Button className={styles.bookBtn}>Book</Button>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightListing;
