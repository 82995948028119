import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { localStorageName, ScreenTypes } from "../../enums";
import {
  ROUTES,
  SENDING_CHAT_MESSAGE,
  VIEW_RECOMMENDED_TRIPS,
} from "../../constant";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { RESPONSE_DUMMY } from "../../constant/responseDummy";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  scrollToBottom,
} from "../../utils";
import { APP_CONFIG } from "../../constant/common";
import toast from "react-hot-toast";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { useNavigate } from "react-router-dom";
import { getFrequentlyViewTripsApi } from "../../api/getFrequentlyViewTrips";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import ReactGA from "react-ga4";
import SpeechRecognition from "react-speech-recognition";
import { v4 } from "uuid";
import { iRecommendedTripContext } from "./recommendedTripContextInterface";
import { getUserRecommendedTripBasedOnPreferencesAPI } from "../../api/getUserRecommendTripBasedOnPreferences";

const RecommendedTripContext = createContext({} as iRecommendedTripContext);

export const RecommendedTripContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    setShowMoreFlag,
    setApiResponse,
    setOpenOverlayScreen,
    setChatData,
    setSharedItineraryId,
    setIsItinerarySavedFlag,
    setenableMultiSelect,
    setScreen,
    openDetailsSliderFlag,
    setCallSaveHistoryAPIFlag,
    userDetails,
    appConfig,
    setSelectedChatData,
    isMobile,
  }: iGlobalContext = context;
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [temFreqViewTrips, setFreqViewTrips] = useSessionStorage(
    localStorageName.TriptroveFrequentlyViewTrips,
    [] as any
  );
  const [index, setIndex] = useState({
    first: 0,
    last: 3,
  });
  useEffect(() => {
    setIndex({
      first: 0,
      last: temFreqViewTrips.length - 1,
    });
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 5320,
      });
    }
  }, [temFreqViewTrips]);

  const [frequentlyViewTrips, setFrequentlyViewTrips] = useState([] as any[]);
  async function getFrequentlyViewTrips() {
    const response = await getFrequentlyViewTripsApi();
    if (response && response?.statusCode === 200) {
      setFreqViewTrips(response?.data);
      setFrequentlyViewTrips([
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
      ]);
    } else {
    }
  }

  const [urlParamQuestion, setURLParamQuestion] = useState("");

  /**
   * Side effect to listen if user has added the question in the URL
   */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("prompt");
    console.log("DATA DATA DATA", data);
    if (data) {
      const formattedData = decodeURIComponent(data)
        .replace(/\+/g, " ")
        .replace(/=/g, "")
        .replace(/\?/g, "");
      setURLParamQuestion(formattedData);
      setChatInputText(formattedData);
    }
  }, []);

  useEffect(() => {
    if (urlParamQuestion) {
      setChatInputText(urlParamQuestion);
      if (chatInputText) handleCreateItinerary();
    }
  }, [urlParamQuestion]);

  //   useEffect(() => {
  //     getFrequentlyViewTrips();
  //   }, []);

  /**
   * Get user recomended trip
   */
  useEffect(() => {
    async function getUserRecommendedTripBasedOnPreferences() {
      let response = await getUserRecommendedTripBasedOnPreferencesAPI(
        "85df03ef-082e-4cb1-9e5c-3c0281c7da8a"
      );
      console.log("RECOMMEND _ 1", response);
      response = checkIfThereIsDestination(response);
      console.log("RECOMMEND _ 2", response);
      const citiesList = response?.data?.cities;
      if (citiesList?.length > 0) {
        console.log("HERE IT IS RES", citiesList);
        const tempResponse = citiesList.map((ele: any) => ({
          ...ele,
          id: v4(),
          // checkedFlag: response?.data?.singleCity,
          checkFlag: false,
          checkedFlag: true,
          locations: [],
        }));
        const currentItineraryList = tempResponse;
        for (let i = 0; i < currentItineraryList.length; i++) {
          const cityName = currentItineraryList[i].cityName;
          let imageRespone: any = await getItineraryImageURL(cityName);
          let count = 0;
          while (!imageRespone || !imageRespone?.data?.image) {
            // Limiting the number of API call to 10 times incase of 409 error
            count++;
            if (count === 5) break;
            // re calling the API
            imageRespone = await getItineraryImageURL(cityName);
          }
          if (imageRespone?.statusCode === 200) {
            currentItineraryList[i].imageURL = imageRespone.data.image;
          } else {
            currentItineraryList[i].imageURL = "https://error.error";
            console.log("RESPONSE TOP Error");
          }
        }

        const data: any = {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,

          complete_itinerary_description:
            RESPONSE_DUMMY.complete_itinerary_description,
        };
        console.log("RECOMMEND _ 3", data);

        setFreqViewTrips([data]);
        setFrequentlyViewTrips([data, data, data, data, data, data, data]);
      }
    }
    // if (userDetails?.loggedIn) {
    //   getUserRecommendedTripBasedOnPreferences();
    // } else {
    //   getFrequentlyViewTrips();
    // }

    getFrequentlyViewTrips();
  }, []);

  const navigate = useNavigate();
  async function handleCreateItinerary() {
    setCallSaveHistoryAPIFlag(false);
    scrollToBottom(scrollContainerRef);
    setenableMultiSelect(false);
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setOpenOverlayScreen(true);
    setDisableInput(true);
    SpeechRecognition.stopListening();
    setApiResponse([]);
    setShowMoreFlag(false);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userDetails?.id || "",
      chatInputText,
      [],
      abortController.signal
    );
    if (!response) {
      // toastMessage.error(
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
      // setChatData(chatData.splice(0, -1))
      setDisableInput(false);
      return;
    }
    setOpenOverlayScreen(false);
    response = checkIfThereIsDestination(response);
    const citiesList = response?.data?.cities;
    if (citiesList?.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList?.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkFlag: false,
        locations: [],
      }));
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,

          complete_itinerary_description:
            RESPONSE_DUMMY.complete_itinerary_description,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName = currentItineraryList[i].cityName;
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 10) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          console.log("RESPONSE TOP Error");
        }
      }
    } else {
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
    navigate(ROUTES.TopDestination);
    setOpenOverlayScreen(false);
    setDisableInput(false);
    setScreen("");
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
    setCallSaveHistoryAPIFlag(true);
  }

  const [, setSelectedRecommendedTripId] = useState("");

  function handleViewItineraryDetails(data: any) {
    setSelectedRecommendedTripId(data.id);
    if (!userDetails?.loggedIn) setSharedItineraryId(`${data.id}` || "");
    else {
      setSelectedChatData(data);
    }
    setIsItinerarySavedFlag(true);
    ReactGA.event({
      category: VIEW_RECOMMENDED_TRIPS.CATEGORY,
      action: VIEW_RECOMMENDED_TRIPS.ACTION,
      label: ` ${VIEW_RECOMMENDED_TRIPS.LABEL}  ${data?.tripName}`,
    });
    if (isMobile) navigate(ROUTES.SavedItineraryDetails);
    setScreen(ScreenTypes.SavedItinerary);
  }

  useEffect(() => {
    if (openDetailsSliderFlag === false) setSelectedRecommendedTripId("");
  }, [openDetailsSliderFlag]);

  // const scrollHandler = (elementRef: any) => {
  //   elementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  // };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setOpenOverlayScreen(false);
      setChatInputText("");
      setDisableInput(false);
    }
  };
  function handleScrollLeft() {
    if (index.first > 0 && sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -190,
        behavior: "smooth",
      });
      setIndex({
        first: index.first - 1,
        last:
          (index.last - 1 + temFreqViewTrips.length) % temFreqViewTrips.length,
      });
    }
  }

  function handleScrollRight() {
    setFrequentlyViewTrips([
      ...frequentlyViewTrips,
      temFreqViewTrips[index.first],
    ]);
    setIndex({
      first: (index.first + 1) % temFreqViewTrips.length,
      last: (index.last + 1) % temFreqViewTrips.length,
    });
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 190,
        behavior: "smooth",
      });
    }
  }

  const value = {
    scrollContainerRef,
    frequentlyViewTrips,
    index,
    handleScrollLeft,
    sliderRef,
    handleViewItineraryDetails,
    handleScrollRight,
    disableInput,
    handleCreateItinerary,
    handleCancel,
    setDisableInput,
  };
  return (
    <RecommendedTripContext.Provider value={value}>
      {children}
    </RecommendedTripContext.Provider>
  );
};
export const useRecommendedTripContext = () =>
  useContext(RecommendedTripContext);
