import { useState } from "react";
import { arrowIcon } from "../../../constant";
import DateRangePickerPopover from "../bookingPopover/dateRangePickerPopover/DateRangePickerPopover";
import styles from "./datePicker.module.scss";

interface iProps {
  className?: string;
  title: string;
  startDate: string;
  endDate: string;
}

type ValuePiece = string;
// type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

function DatePicker(props: iProps): JSX.Element {
  const { className, title, startDate, endDate } = props;
  // const [dateRangeValues, setDateRangeValues] = useState<Value>(new Date());
  const [dateRangeValues, setDateRangeValues] = useState<Value | null>(null);
  
  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options).toUpperCase();
  };



  const startDates = Array.isArray(dateRangeValues) ? formatDate(dateRangeValues[0]) : 'Fri, DEC 18';
  const endDates = Array.isArray(dateRangeValues) ? formatDate(dateRangeValues[1]) : 'Thu, DEC 24';


  return (
    <div className={`${className} ${styles.travelCard}`}>
      <p className={`${className} ${styles.travelTitle}`}>{title}</p>

      <DateRangePickerPopover
        value={dateRangeValues}
        setValue={setDateRangeValues}
        popoverTrigger={
          <div className={styles.travelBtn}>
            {startDates}{" "}
            <img src={arrowIcon} alt="" className={styles.arrowIcon} />{" "}
            {endDates}
          </div>
        }
      />
    </div>
  );
}

export default DatePicker;
