import { arrowRightArrowLeftRegular } from "../../../constant";
import { BookingDetails } from "../../../types";
import AirportSelectionPopover from "../bookingPopover/airportSelectionPopover/AirportSelectionPopover";
import styles from "./flight.module.scss";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

function AirPortPicker({
  className,
  bookingDetails,
  setBookingDetails,
}: iProps): JSX.Element {
  return (
    <div className={`${className} ${styles.travelCard}`}>
      <p className={`${className} ${styles.travelTitle}`}>AIRPORTS</p>

      {bookingDetails && (
        <AirportSelectionPopover
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          popoverTrigger={
            <div className={styles.travelBtn}>
              {bookingDetails.airportFrom}{" "}
              <img
                src={arrowRightArrowLeftRegular}
                className={styles.arrowIcon}
                alt=""
              />{" "}
              {bookingDetails.airportTo}
            </div>
          }
        />
      )}
    </div>
  );
}

export default AirPortPicker;
