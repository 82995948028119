import React, { useContext, useEffect, useRef, useState } from "react";
import { Stack, Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { checkIfUserIsLoggedIn } from "../../utils/loginAndLocalStorageutils";
import BackButtonHandler from "../../component/BackButtonHandler";
import { APP_URL } from "../../constant/environment";
import styles from "./itineraryDetails.module.scss";
import { iGlobalContext } from "../../context/globalContext/interface";
import ReactGA from "react-ga4";
import { DialogSelected } from "../../constant/common";
import CityTabs from "./cityTabs/CityTabs";
import {
  backIcon,
  buyIcon,
  closeWhiteIcon,
  editIcon,
  ROUTES,
  saveIcon,
  SENDING_CHAT_MESSAGE,
  shareIcon,
} from "../../constant";
import { iChatData, iCityDetailsResult } from "../../types";
import { checkIfThereIsDestination, scrollToTop } from "../../utils";
import Loader from "../../component/reusableComponent/loader/Loader";
import { ScreenTypes } from "../../enums";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { v4 } from "uuid";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
let abortPlanTripDetails = new AbortController();

export default function ItineraryDetails() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setSaveScreenState,
    setLoginCreateAccountState,
    setNav,
    setIsLoggedIn,
    setMenuOpenState,
    tripForNDays,
    setTripForNDays,
    singleItineraryDetails,
    setExtraMeta,
    extraMeta,
    sharedItineraryId,
    setSharedItineraryId,
    setUserDetails,
    setDataStream,
    query,
    setShareClick,
    isItinerarySavedFlag,
    setIsItinerarySavedFlag,
    setSelectedChatData,
    chatData,
    selectedChatData,
    scrollContainerRef,
    setScreen,
    isMobile,
    screen,
    setChatData,
    userInfo,
    setUserInfo,
    setModifyItineraryPrompt,
    userDetails,
  }: iGlobalContext = state;
  // Axios controller to cancel API call
  const controller = new AbortController();
  const controllerAbortUpdateTrip = new AbortController();
  const abortControllerRef = useRef<AbortController | null>(null);
  React.useEffect(() => {
    scrollToTop(scrollContainerRef);
    setNav("");
    window.scrollTo(0, 0);
    setTripForNDays([]);
    setDataStream("");
    const localPlanTrip = window.sessionStorage.getItem("_extra_meta");
    if (localPlanTrip !== null) {
      setTripForNDays(JSON.parse(localPlanTrip).planTripArray);
    }
    if (!userInfo.loggedIn) checkIfUserIsLoggedIn(setUserInfo);
    return () => {
      controllerAbortUpdateTrip.abort();
      controller.abort();
      setDataStream("");
    };
  }, []);

  React.useEffect(() => {
    console.log("Shared ID", sharedItineraryId);
    if (sharedItineraryId !== "") setIsItinerarySavedFlag(true);
    else {
      setIsItinerarySavedFlag(false);
    }
  }, [sharedItineraryId]);

  // Save Itinerary
  function handleSaveItinerary() {
    setExtraMeta({
      ...extraMeta,
      planTripArray: tripForNDays,
      query: query,
    });

    setMenuOpenState(false);
    setSaveScreenState(true);
    if (userInfo.loggedIn)
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.SAVE_ITINERARY,
      });
    else setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
  }

  // Onclick function to handle Back button click
  function handleBackButton() {
    if (screen === ScreenTypes.SingleItinerary) {
      setScreen("");
      return;
    }

    if (isMobile) {
      navigate(ROUTES.TopDestination);
    } else {
      setScreen(ScreenTypes.Reorder);
    }
  }

  async function handleShare() {
    if (!isItinerarySavedFlag) {
      setShareClick(true);
      setTimeout(() => {
        handleSaveItinerary();
      }, 1000);
      toastMessage.info("Please save the Itinerary before sharing!");
    }
  }

  const [noOfCitySelected, setNoOfCitySelected] = useState(1);
  useEffect(() => {
    setNoOfCitySelected(
      selectedChatData?.destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      )
    );
  }, [selectedChatData]);

  function handleOnShare() {
    console.log(sharedItineraryId, "PARAMS URL");
    ReactGA.event({
      category: "Button Click",
      action: `Click on Share Trip Details`,
      label: `User is sharing the itinerary ${APP_URL}/shared/${sharedItineraryId}`,
    });
  }

  const [tempSelectedChatData, setTempSelectedChatData] = useState(
    selectedChatData?.destinationList?.filter(
      (ele: iCityDetailsResult) => ele.checkedFlag
    )
  );

  useEffect(() => {
    setTempSelectedChatData(
      selectedChatData?.destinationList?.filter(
        (ele: iCityDetailsResult) => ele.checkedFlag
      )
    );
    console.log(selectedChatData);
  }, [selectedChatData]);

  function handleEdit() {
    if (isMobile) navigate(ROUTES.TopDestination);
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    );
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: selectedChatData?.prompt,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: selectedChatData?.prompt,
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
  }

  async function handleSendMessage() {
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    ); //  used to enable auto scroll in Destop
    // setCallSaveHistoryAPIFlag(false);
    // scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info("Please wait, we are still fetching the result!");
      return;
    }
    if (inputValue === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: selectedChatData?.prompt,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setInputValue("");
    setDisableInput(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userDetails?.id || "",
      inputValue,
      selectedChatData?.prompt,
      abortController.signal
    );
    if (!response) {
      setDisableInput(false);
      return;
    }
    response = checkIfThereIsDestination(response);
    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    processedAndGetDestinationImageURL(citiesList, response, false);
    setDisableInput(false);
    // scrollToBottom(desktopScrollContainerRef);
    // setCallSaveHistoryAPIFlag(true);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${inputValue}`,
    });
  }
  async function processedAndGetDestinationImageURL(
    citiesList: iCityDetailsResult[],
    response: any,
    showMore: boolean
  ) {
    if (citiesList.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkedFlag: true,
        locations: [],
      }));

      const newId = v4();
      setSelectedChatData({
        fromCity: response?.data?.from,
        noOfDays: response?.data?.numberOfdays,
        id: newId,
        message: "",
        preText: response?.data?.preText,
        postText: response?.data?.postText,
        prompt: response?.data?.messages,
        isUserMessage: false,
        isLoading: false,
        singleSelectedDestination: response?.data?.singleCity,
        destinationList: tempResponse,
        itinerary_version: response?.data?.itinerary_version || 1,
      });
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: showMore ? prev[prev.length - 1].id : newId,
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: showMore
            ? [...prev[prev.length - 1].destinationList, ...tempResponse]
            : tempResponse,
          itinerary_version: response?.data?.itinerary_version || 0,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName = currentItineraryList[i].cityName;
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 10) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          console.log("RESPONSE TOP Error");
        }
      }

      ReactGA.event({
        category: "Top Destination",
        action: `User has recieved the itinerary list created`,
        label: `User prompt is ${response?.data?.query}`,
      });
    } else {
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
  }

  /**
   * chat input
   */
  const [inputValue, setInputValue] = useState("");

  /**
   * Handling cancelling Send message or
   * open Itinerary details or Show More
   */
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [showChatLoaderFlag, setShowChatLoaderFlag] = useState(false);
  function handleCancel() {
    setShowChatLoaderFlag(false);
    setScreen("");
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    abortPlanTripDetails.abort();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setDisableInput(false);
    }
  }

  /**
   * Editing activity
   */
  function handleEditActivity(data: any) {
    console.log(data);
  }

  if (JSON.stringify(selectedChatData) === "{}")
    return (
      <Stack mt={5}>
        <Loader />
      </Stack>
    );

  const handleNav = () => {
    isMobile ? navigate(ROUTES.AdjustTrip) : setScreen(ScreenTypes.Adjust);
  };

  const handleBook = () => {
    isMobile ? navigate(ROUTES.Booking) : setScreen(ScreenTypes.Booking);
  };

  return (
    <div className={styles.desktopScrollContainer}>
      {/* {disableInput && (
        <div className={styles.overlay}>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </div>
      )} */}

      <div className={styles.mainContainer}>
        <BackButtonHandler onBack={handleBackButton} />
        {/* Save and Share Button */}
        <div className={styles.shareSaveAdjustContainer}>
          {noOfCitySelected > 1 &&
            !selectedChatData.singleSelectedDestination && (
              <Button
                onClick={handleNav}
                className={`${styles.button} ${styles.adjustButton}`}
              >
                <img className={styles.iconButton} src={editIcon} alt="share" />
                ADJUST
              </Button>
            )}
        </div>

        {/* Itinerary Details */}
        <div className={styles.stickyHeading}>
          <div className={styles.closeBtnContainer}>
            <Button
              className={`${styles.columnAlignButton}`}
              onClick={handleBackButton}
            >
              <img src={backIcon} alt="Close" />
              <p>BACK</p>
            </Button>
          </div>
          <div className={styles.buttonContainer}>
            {/* <Button className={`${styles.rowAlignButton}`} onClick={handleEdit}>
            <img className={styles.iconButton} src={editIcon} alt="edit" />
            EDIT
          </Button> */}
            {isItinerarySavedFlag ? (
              <RWebShare
                data={{
                  url: `${APP_URL}shared/${sharedItineraryId}`,
                  title: "Zenvoya",
                }}
                onClick={handleOnShare}
              >
                <Button
                  className={`${styles.rowAlignButton}`}
                  onClick={handleShare}
                >
                  <img
                    className={styles.iconButton}
                    src={shareIcon}
                    alt="share"
                  />
                  SHARE
                </Button>
              </RWebShare>
            ) : (
              <Button
                className={`${styles.rowAlignButton}`}
                onClick={handleShare}
              >
                <img
                  className={styles.iconButton}
                  src={shareIcon}
                  alt="share"
                />
                SHARE
              </Button>
            )}
            <Button
              onClick={handleSaveItinerary}
              className={`${styles.rowAlignButton} ${styles.saveBtn}`}
            >
              <img className={styles.iconButton} src={saveIcon} alt="save" />
              SAVE
            </Button>
            <Button className={`${styles.rowAlignButton}`} onClick={handleBook}>
              <img className={styles.iconButton} src={buyIcon} alt="buy" />
              Book
            </Button>
          </div>
        </div>

        {/* <div className={styles.headingContainer}>
        {noOfCitySelected === 1 && tempSelectedChatData?.length > 0 && (
          <div className={styles.headingText}>
            <h1>{tempSelectedChatData[0]?.cityName.split(",")[0]}</h1>
            <h2>
              {
                tempSelectedChatData[0]?.cityName.split(",")[
                  tempSelectedChatData[0]?.cityName.split(",").length - 1
                ]
              }
            </h2>
          </div>
        )}
      </div> */}

        {/* Tab container */}
        <CityTabs
          itineraryVersion={selectedChatData?.itinerary_version || 0}
          destinationList={tempSelectedChatData}
          handleEditActivity={handleEditActivity}
          disableInput = {disableInput}
        />
      </div>
      <div className={styles.inputContainer}>
        <InputWithMic
          placeholder="Type here to update the itinerary"
          disable={disableInput}
          onSend={() => handleSendMessage()}
          onStopRecording={() => {}}
          value={inputValue}
          setValue={setInputValue}
          handleCancel={handleCancel}
          disableSendBtn={inputValue === "" ? true : false}
        />
      </div>
    </div>
  );
}
//  onSend={() => handleSendMessage(chatInputText)}
//               onStopRecording={() => handleSendMessage(chatInputText)}
//               value={chatInputText}
//               setValue={setChatInputText}
//               handleCancel={handleCancel}
//               disableSendBtn={chatInputText === "" ? true : false}
