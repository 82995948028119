export const MainPages = {
  Home: "/",
  Edit: "/edit",
  TopDestination: "/top-destination",
  Personalis: "/personalize",
  ItineraryDetails: "/itinerary-details",
  SavedItineraryList: "/saved-itinerary-list",
  RecentSearch: "/recent-search",
  EditAccount: "/edit-account",
  ResetPassword: "/reset-password",
  SavedItineraryDetails: "/saved-itinerary-details",
  Landing: "/landing",
  RecommendedTrips: "/recommended-trips",
  Testpage: "/testpage",
  AdjustTrip: "/adjustTrip",
  PreviewPreference: "/PreviewPreference",
  ReOrder: "/reorder",
  ReorderMultiCityTrip: "/reorder-multi-city-trip",
  DesktopLandingPage: "/desktopLandingPage",
  SingleItineraryDetails: "/single-itinerary-details",
  NewTrip: "/new-trip",
  Faqs: "/faqs",
  Booking: "/booking",
  HotelDetails: "/hotel-details",
};

export const ROUTES = {
  ...MainPages,
};

export const PUBLIC_ROUTES = [];

export const PROTECTED_ROUTES = [];
