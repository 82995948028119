import { ChangeEvent, useState } from "react";
import styles from "./book.module.scss";
import { Button } from "@mui/material";
import { updatedSeeMoreIcon } from "../../constant";
import CustomCheckbox from "../../component/booking/bookingCheckBox/CustomCheckbox";
import DetailsHeader from "../../component/booking/flightBooking/detailsHeader/detailsHeader";
import FlightListing from "../../component/booking/flightBooking/flightListing/flightListing";
import UserDetails from "../../component/booking/flightBooking/details/details";
import { FlightData, HotelData, PackageData } from "../../constant/dummyData";
import SelectedOptions from "../../component/booking/selectedOptions/selectedOptions";
import HotelDetailsHeader from "../../component/booking/hotelBooking/hotelDetailsHeader/detailsHeader";
import HotelUserDetails from "../../component/booking/hotelBooking/details/details";
import HotelListing from "../../component/booking/hotelBooking/hotelListing/hotelListing";
import PackageBooking from "../../component/booking/packageBooking/packageBooking";
import FlightSelectButtons from "../../component/booking/flightSelectButton/flightSelectButton";
import DatePicker from "../../component/booking/datePicker/datePicker";
import SearchButton from "../../component/booking/searchBtn/searchBtn";
import AdditionalDetails from "../../component/booking/packageBooking/additionalDetails/additionalDetails";
import { BookingDetails } from "../../types";

function Booking(): JSX.Element {
  const [selectedOptions, setSelectedOptions] = useState({
    flights: true,
    hotels: false,
  });
  const [showDetails, setShowDetails] = useState(false);
  const [visibleList, setVisibleList] = useState({
    flight: 4,
    hotel: 4,
    package: 2,
  });

  const [bookingDetails, setBookingDetails] = useState<BookingDetails>({
    adultsCount: 1,
    childrenCount: 2,
    seniorsCount: 0,
    airportFrom: "IAD",
    airportTo: "BCN",
    travelFromDate: "",
    travelToDate: "",
    routeType: "",
    flightClass: "",
    city: "Barcelona",
    checkIn: "",
    checkOut: "",
    numberOfRoomCount: 1,
  });

  const handleShowMoreFlights = () => {
    setVisibleList((prevVisibleList) => ({
      ...prevVisibleList,
      flight: prevVisibleList.flight + 4,
    }));
  };

  const handleShowMoreHotels = () => {
    setVisibleList((prevVisibleList) => ({
      ...prevVisibleList,
      hotel: prevVisibleList.hotel + 4,
    }));
  };

  const handleShowMorePackages = () => {
    setVisibleList((prevVisibleList) => ({
      ...prevVisibleList,
      package: prevVisibleList.package + 2,
    }));
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.titleCard}>
          <p className={styles.bookingTitle}>Booking</p>
          <section className={styles.checkCard}>
            <div className={styles.checkBox}>
              <CustomCheckbox
                label="Flights"
                name="flights"
                handleCheckboxChange={handleChange}
                selectedOptions={selectedOptions}
              />
              <CustomCheckbox
                label="Hotels"
                name="hotels"
                handleCheckboxChange={handleChange}
                selectedOptions={selectedOptions}
              />
            </div>
            {selectedOptions.flights && selectedOptions.hotels && (
              <div className={styles.searchCard}>
                <DatePicker
                  title="TRAVEL DATES"
                  startDate="Fri, DEC 18"
                  endDate="Thu, DEC 25"
                  className={styles.travelTitle}
                />
                <SearchButton />
              </div>
            )}
          </section>
        </div>
        {selectedOptions.flights && !selectedOptions.hotels && (
          <section>
            {showDetails ? (
              <section className={styles.detailsCard}>
                <DetailsHeader onClick={handleShowDetails} />
              </section>
            ) : (
              <UserDetails
                onClick={handleShowDetails}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
              />
            )}
          </section>
        )}
        {selectedOptions.flights && selectedOptions.hotels && (
          <section>
            <AdditionalDetails
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
            />
          </section>
        )}

        {/* Flight listing */}

        {selectedOptions.hotels && !selectedOptions.flights && (
          <section>
            {showDetails ? (
              <section className={styles.detailsCard}>
                <HotelDetailsHeader onClick={handleShowDetails} />
              </section>
            ) : (
              <HotelUserDetails
                onClick={handleShowDetails}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
              />
            )}
          </section>
        )}

        {selectedOptions.flights && !selectedOptions.hotels && (
          <section className={styles.list}>
            <section className={styles.selected}>
              <SelectedOptions selectedOptions={selectedOptions} />
            </section>
            <section className={styles.listCard}>
              {FlightData.slice(0, visibleList.flight).map((i, index) => (
                <div key={index}>
                  <FlightListing
                    startTime={i.startTime}
                    endTime={i.endTime}
                    flightName={i.flightName}
                    stopsInfo={i.stopsInfo}
                    stops={i.stops}
                    stopTime={i.stopTime}
                    airports={i.airports}
                    flightCost={i.flightCost}
                    flightClass={i.flightClass}
                    tourPackage={false}
                  />
                </div>
              ))}
              {visibleList.flight < FlightData.length && (
                <div className={styles.showCard}>
                  <Button
                    className={styles.showMoreBtn}
                    endIcon={
                      <img
                        src={updatedSeeMoreIcon}
                        alt="btn"
                        className={styles.showMoreIcon}
                      />
                    }
                    onClick={handleShowMoreFlights}>
                    Show More
                  </Button>
                </div>
              )}
            </section>
          </section>
        )}

        {/* Hotel listing */}

        {selectedOptions.hotels && !selectedOptions.flights && (
          <section className={styles.list}>
            <section className={styles.selected}>
              <SelectedOptions selectedOptions={selectedOptions} />
            </section>
            <section className={styles.listCard}>
              {HotelData.slice(0, visibleList.hotel).map((i, index) => (
                <div key={index}>
                  <HotelListing
                    star={i.star}
                    hotelName={i.hotelName}
                    hotelLocation={i.hotelLocation}
                    hotelRating={i.hotelRating}
                    reviewCount={i.reviewCount}
                    cost={i.cost}
                    cancellation={i.cancellation}
                    Amenity={i.Amenities}
                    hotelList={true}
                  />
                </div>
              ))}
              {visibleList.hotel < HotelData.length && (
                <div className={styles.showCard}>
                  <Button
                    className={styles.showMoreBtn}
                    endIcon={
                      <img
                        src={updatedSeeMoreIcon}
                        alt="btn"
                        className={styles.showMoreIcon}
                      />
                    }
                    onClick={handleShowMoreHotels}>
                    Show More
                  </Button>
                </div>
              )}
            </section>
          </section>
        )}

        {/* Package listing */}
        {selectedOptions.hotels && selectedOptions.flights && (
          <>
            <section className={styles.list}>
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={selectedOptions}
                  selectTitle="package below"
                />
              </section>
              <section className={styles.listCard}>
                {PackageData.slice(0, visibleList.package).map((i, index) => (
                  <div key={index}>
                    <PackageBooking
                      startTime={i.startTime}
                      endTime={i.endTime}
                      flightName={i.flightName}
                      stopsInfo={i.stopsInfo}
                      stopTime={i.stopTime}
                      flightCost={i.flightCost}
                      flightClass={i.flightClass}
                      star={i.star}
                      hotelName={i.hotelName}
                      hotelLocation={i.hotelLocation}
                      roomType={i.roomType}
                    />
                  </div>
                ))}
                {visibleList.package < PackageData.length && (
                  <div className={styles.packageShowMoreCard}>
                    <Button
                      className={styles.showMoreBtn}
                      endIcon={
                        <img
                          src={updatedSeeMoreIcon}
                          alt="btn"
                          className={styles.showMoreIcon}
                        />
                      }
                      onClick={handleShowMorePackages}>
                      Show More
                    </Button>
                  </div>
                )}
              </section>
            </section>
            <section className={styles.list}>
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={{ hotels: false, flights: true }}
                  selectTitle="flight below"
                />
              </section>
              <section className={styles.listCard}>
                {FlightData.slice(0, visibleList.flight).map((i, index) => (
                  <div key={index}>
                    <FlightListing
                      startTime={i.startTime}
                      endTime={i.endTime}
                      flightName={i.flightName}
                      stopsInfo={i.stopsInfo}
                      stops={i.stops}
                      stopTime={i.stopTime}
                      airports={i.airports}
                      flightCost={i.flightCost}
                      flightClass={i.flightClass}
                      tourPackage={true}
                    />
                  </div>
                ))}
                {visibleList.flight < FlightData.length && (
                  <div className={styles.packageShowMoreCard}>
                    <Button
                      className={styles.showMoreBtn}
                      endIcon={
                        <img
                          src={updatedSeeMoreIcon}
                          alt="btn"
                          className={styles.showMoreIcon}
                        />
                      }
                      onClick={handleShowMoreFlights}>
                      Show More
                    </Button>
                  </div>
                )}
              </section>
            </section>
            <div className={styles.divider} />
            <section className={styles.list}>
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={{ hotels: true, flights: false }}
                />
              </section>

              <section className={styles.listCard}>
                {HotelData.slice(0, visibleList.hotel).map((i, index) => (
                  <div key={index}>
                    <HotelListing
                      star={i.star}
                      hotelName={i.hotelName}
                      hotelLocation={i.hotelLocation}
                      hotelRating={i.hotelRating}
                      reviewCount={i.reviewCount}
                      cost={i.cost}
                      cancellation={i.cancellation}
                      Amenity={i.Amenities}
                      hotelList={true}
                    />
                  </div>
                ))}

                {visibleList.hotel < HotelData.length && (
                  <div className={styles.packageShowMoreCard}>
                    <Button
                      className={styles.showMoreBtn}
                      endIcon={
                        <img
                          src={updatedSeeMoreIcon}
                          alt="btn"
                          className={styles.showMoreIcon}
                        />
                      }
                      onClick={handleShowMoreHotels}>
                      Show More
                    </Button>
                  </div>
                )}
              </section>
              <section className={styles.buttonCard}>
                <FlightSelectButtons />
              </section>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default Booking;
