import { Button } from "@mui/material";
import styles from "./flight.module.scss";

function FlightSelectButtons(): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Button className={styles.continue}>Continue</Button>
        <Button className={styles.select}>
          SELECT A FLIGHT & HOTEL TO BOOK
        </Button>
      </div>
    </div>
  );
}

export default FlightSelectButtons;
