import TravelerPopover from "../bookingPopover/travelerPopover/TravelerPopover";
import styles from "./traveller.module.scss";
import { BookingDetails } from "../../../types";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

type CountType =
  | "adultsCount"
  | "childrenCount"
  | "seniorsCount"
  | "numberOfRoomCount";

function TravelPicker(props: iProps): JSX.Element {
  const { className, bookingDetails, setBookingDetails } = props;

  const setCount = (
    type: CountType
  ): React.Dispatch<React.SetStateAction<number>> => {
    return (value) => {
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        [type]: typeof value === "function" ? value(prevDetails[type]) : value,
      }));
    };
  };

  const setAdultsCount = setCount("adultsCount");
  const setChildrenCount = setCount("childrenCount");
  const setSeniorsCount = setCount("seniorsCount");
  const setNumberOfRoomCount = setCount("numberOfRoomCount");

  return (
    <div className={`${className} ${styles.travelCard}`}>
      <p className={`${className} ${styles.travelTitle}`}>Travelers</p>

      <TravelerPopover
        adultsCount={bookingDetails.adultsCount}
        setAdultsCount={setAdultsCount}
        childrenCount={bookingDetails.childrenCount}
        setChildrenCount={setChildrenCount}
        seniorsCount={bookingDetails.seniorsCount}
        setSeniorsCount={setSeniorsCount}
        numberOfRoomCount={bookingDetails.numberOfRoomCount}
        setNumberOfRoomCount={setNumberOfRoomCount}
        popoverTrigger={
          <div className={styles.travelBtn}>
            {bookingDetails?.adultsCount > 0 ? (
              <span>{bookingDetails?.adultsCount} Adult,</span>
            ) : (
              <span>1 Adult,</span>
            )}
            {bookingDetails?.childrenCount > 0 && (
              <span>{bookingDetails?.childrenCount} Children,</span>
            )}
            {bookingDetails?.numberOfRoomCount > 0 && (
              <span>&nbsp;|&nbsp;{bookingDetails?.numberOfRoomCount} Room</span>
            )}
          </div>
        }
        showRoom={true}
      />
    </div>
  );
}

export default TravelPicker;
