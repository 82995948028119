import { updatedCloseIcon } from "../../../../constant";
import TravelPicker from "../../travellerPicker/travellerPicker";
import DatePicker from "../../datePicker/datePicker";
import styles from "./detail.module.scss";
import SearchButton from "../../searchBtn/searchBtn";
import { BookingDetails } from "../../../../types";
interface iProps {
  onClick: () => void;
  bookingDetails?: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}

function HotelUserDetails({
  onClick,
  bookingDetails,
  setBookingDetails,
}: iProps): JSX.Element {
  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCity = e.target.value;
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      city: newCity,
    }));
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.closeCard}>
          <img
            src={updatedCloseIcon}
            alt=""
            className={styles.closeIcon}
            onClick={onClick}
          />
        </div>

        <section className={styles.detailCard}>
          <p className={styles.title}>Details</p>
          <div className={styles.inputCard}>
            <div className={styles.inputBox}>
              {bookingDetails && (
                <TravelPicker
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                />
              )}

              <div className={styles.travelCard}>
                <p className={styles.travelTitle}>City</p>
                <div className={styles.travelBtn}>
                  <input
                    type="text"
                    value={bookingDetails?.city}
                    onChange={handleCityChange}
                    className={styles.inputField}
                  />
                </div>
              </div>
              <DatePicker
                title="Check-in"
                startDate="Fri, DEC 18"
                endDate="Wed, DEC 23"
              />
              <SearchButton />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HotelUserDetails;
