import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function getItineraryImageURL(cityName: string) {
  // const URL = `${ENDPOINT}/app/v1/itineraries/?imageName=${cityName}`;

  // let config = {
  //   headers: {
  //     "Content-Type": "application/json;charset=UTF-8",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Headers": "*",
  //   },
  // };

  // try {
  //   const response = await axios.get(URL, config);
  //   console.log("FROM API", response);
  //   return response.data;
  // } catch (error: any) {
  //   if (error.response && error.response.status === 409) {
  //     console.log("ERROR STATUS", error.response.status);
  //     console.log("ERROR", error.response);
  //   } else console.log("NORMAL ERROR", error);
  //   return null;
  // }
  return {
    statusCode: 200,
    data: {
      image: "https://error.error"
    }
  }
}
