import React, { useEffect, useState } from "react";
import styles from "./destinationCardWithImages.module.scss";
import { iCityDetailsResult } from "../../../types";
import { arrowNextIcon, multiCityIcon, selectedIcon } from "../../../constant";
import { Skeleton } from "@mui/material";
import { currencyToNumber } from "../../../utils";

export default function DestinationCardWithImages({
  destinationList,
  showArrowIcon = true,
  itineraryVersion = 0,
}: {
  destinationList: iCityDetailsResult[];
  showArrowIcon?: boolean;
  itineraryVersion?: string | number;
}) {
  const [timeOutFlag, setTimeOutFlag] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setTimeOutFlag(false);
    }, 2000);
    console.log("YES", destinationList);
  }, [destinationList]);
  const selected =
    (destinationList[0]?.checkedFlag && Number(itineraryVersion) > 0) || false;
  return (
    <div className={styles.mainContainer}>
      {destinationList.length > 1 && (
        <div className={styles.multiCityIconContainer}>
          <img src={multiCityIcon} alt="" />
          <h1>MULTI-CITY</h1>
        </div>
      )}
      <div className={styles.imageContainer}>
        {destinationList[destinationList.length - 1]?.imageURL ? (
          destinationList.map((ele: iCityDetailsResult) => (
            <img
              src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = `${100 / destinationList.length}%`;
              }}
              style={{ width: `${100 / destinationList.length}%` }}
              className={styles.img}
              // src={ele.imageURL}
              alt="city Image"
            />
          ))
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <div
        className={`
        ${styles.placeHeadingContainer}
        ${selected && styles.placeHeadingContainerSelected}
        `}
      >
        <div className={styles.textContainer}>
          <div
            className={`${styles.midContainer}
             ${selected && styles.midContainerSelected}`}
          >
            {selected && Number(itineraryVersion) > 0 && (
              <div className={styles.versionContainer}>
                <p>{itineraryVersion}</p>
              </div>
            )}

            <div
              className={`
              ${
                selected
                  ? styles.cityCountryPriceContainerSelected
                  : styles.cityCountryPriceContainer
              }
              `}
              style ={{marginRight: showArrowIcon ? "30px": "0px"}}
            >
              <div className={styles.cityNameContainer}>
                {destinationList.length > 1 ? (
                  destinationList.map(
                    (ele: iCityDetailsResult, index: number) => (
                      <h1
                        className={styles.placeHeadingMob}
                        style={{ marginTop: "6px" }}
                        key={index}
                      >
                        {index === destinationList.length - 1 ? (
                          ele.cityName.split(",")[0]
                        ) : (
                          <span>{ele.cityName.split(",")[0]},&nbsp;</span>
                        )}
                      </h1>
                    )
                  )
                ) : (
                  <div className={styles.placeHeadingMobContainer}>
                    <h1 className={styles.placeHeadingMob}>
                      {destinationList[0]?.cityName.split(",")[0]}
                    </h1>
                    <h2>
                      {
                        destinationList[0]?.cityName.split(",")[
                          destinationList[0]?.cityName.split(",").length - 1
                        ]
                      }
                    </h2>
                  </div>
                )}
              </div>
              <PriceHelperComponent
                selected={selected}
                destinationList={destinationList}
              />
            </div>
          </div>

          <div style={{display: showArrowIcon ? "flex": "none"}}>
            {
              (selected ? (
                <img
                  className={styles.selectedIcon}
                  // src={selectedIcon}
                  src={arrowNextIcon}
                  alt="selected"
                />
              ) : (
                <img
                  className={styles.nextIcon}
                  src={arrowNextIcon}
                  alt="arrowNextIcon"
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

function PriceHelperComponent({
  selected,
  destinationList,
}: {
  selected: boolean;
  destinationList: iCityDetailsResult[];
}) {
  return (
    <h1
      className={`
    ${styles.priceText}
    `}
    >
      {/* ${selected && styles.priceTextSelected} */}
      from $
      {currencyToNumber(
        destinationList[0]?.complete_itinerary_flights_cost?.value
      ) +
        currencyToNumber(
          destinationList[0]?.complete_itinerary_hotels_cost?.value
        )}
    </h1>
  );
}
