import React, { ReactNode } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./routeClassPopover.module.scss";
import CustomChip from "../../../reusableComponent/customChip/CustomChip";

export default function RouteClassPopover({
  popoverTrigger,
  route,
  setRoute,
  classData,
  setClass,
}: {
  popoverTrigger: ReactNode;
  route: any;
  setRoute: any;
  classData: any;
  setClass: any;
}) {
  function handleChangeRoute() {
    setRoute({
      ...route,
      checkedFlag: !route.checkedFlag,
    });
  }

  function handleChangeClass(id: any) {
    setClass(
      classData.map((ele: any) =>
        ele.id === id
          ? {
              ...ele,
              checkedFlag: !ele.checkedFlag,
            }
          : ele
      )
    );
  }
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      popoverContent={
        <div className={styles.mainContainer}>
          <h1 className={styles.heading}>Route</h1>
          <CustomChip
            onClick={handleChangeRoute}
            label={route.value}
            selected={route.checkedFlag}
          />
          <h1 className={styles.heading}>Class</h1>
          <div className={styles.classChipContainer}>
            {classData?.length > 0 &&
              classData.map((ele: any) => (
                <div key={ele.id}>
                  <CustomChip
                    onClick={() => handleChangeClass(ele.id)}
                    label={ele.value}
                    selected={ele.checkedFlag}
                  />
                </div>
              ))}
          </div>
        </div>
      }
    />
  );
}
