import { iChatData, iCityDetailsResult } from "../types";
import { getSelectedOptionsStringArray } from "./recentlyViewTripsItem";
import { v4 } from "uuid";

export function getSelectedOptionsStringArrayFromAllOptions(options: any) {
  if (!options) return "No data";
  let stringArr: any = [];
  for (let i = 0; i < options.length; i++) {
    const ele = options[i]?.tag;
    const selectedOption = getSelectedOptionsStringArray(ele);
    if (selectedOption !== "") stringArr.push(selectedOption);
  }
  let string = stringArr.join(", ");
  console.log("FREQ", stringArr);
  return string;
}

export function checkAndReturnSingularOrPluralWord(
  number: number,
  word: string
) {
  if (!number) return word;
  else if (number === 1) return number + " " + word;
  else return number + " " + word + "s";
}

// Helper function to join options of string
export function concatenateOptionsToString(array: any) {
  let temp = "";
  const tempArray = array.tag;
  for (let i = 0; i < tempArray.length; i++) {
    if (tempArray[i].check) {
      temp += tempArray[i].data + ", ";
    }
  }
  return temp.substring(0, temp.length - 2);
}

export function extractPlanTripPrompt(
  promptTemplate: string,
  city: string,
  climate: any,
  population: any,
  experiences: any,
  budget: any,
  noOfDays: number | string,
  accomodations: any,
  foodPreferences: any,
  selectedDestFlag: boolean
) {
  let climatePopulationPrompt = "";
  let accomodationsPrompt = "";
  let foodPreferencesPrompt = "";

  if (climate !== "")
    climatePopulationPrompt = ` The itinerary should include places with ${climate} in ${population} areas.`;
  if (selectedDestFlag) climatePopulationPrompt = "";
  if (accomodations !== "")
    accomodationsPrompt = `They would like to stay at (lodging) ${accomodations}.`;
  if (foodPreferences !== "")
    foodPreferencesPrompt = `They prefer ${foodPreferences} food.`;

  const replacements = {
    noOfDays,
    city,
    climatePopulationPrompt,
    accomodationsPrompt,
    foodPreferencesPrompt,
    budget,
    experiences,
  };
  const prompt = replaceWords(promptTemplate, replacements);
  console.log("PROMPT MODIFIED", replacements, prompt);
  return prompt;
}

function replaceWords(prompt: string, replacements: any) {
  const pattern = /<([^>]+)>/g;
  prompt = prompt.replace(pattern, (match, group) => {
    const replacement = replacements[group];
    return replacement ? replacement : "";
  });
  return prompt;
}

/**
 * check if there are cities or destination list
 */
export function checkIfThereIsDestination(response: any) {
  const element = [
    {
      start: "<recommendationsJsonStart>",
      end: "<recommendationsJsonEnd>",
      singleTrip: false,
    },
    {
      start: "<itineraryJsonStart>",
      end: "<itineraryJsonEnd>",
      singleTrip: true,
    },
  ];
  let results: any = {};

  let startIndex = 0;
  let endIndex = 0;

  for (let i = 0; i < element.length; i++) {
    const ele = element[i];
    if (response?.data?.result.indexOf(ele.start) > 0) {
      startIndex = response?.data?.result.indexOf(ele.start) + ele.start.length;
      endIndex = response?.data?.result.indexOf(ele.end);
      if (ele.singleTrip) {
        if (startIndex > 0 && endIndex > 0) {
          let subString = response?.data?.result.substring(
            startIndex,
            endIndex
          );
          results = JSON.parse(subString);
        }
        console.log("HERE IT IS RESULT REAL", results);
        if (JSON.stringify(results) !== "{}") {
          response = {
            ...response,
            data: {
              ...response.data,
              from: results?.from,
              // numberOfdays: results?.number_of_days,
              numberOfdays:
                results?.city_wise_itinerary?.reduce(
                  (acc: number, ele: any) => ele?.city_itinerary.length + acc,
                  0
                ) || null,
              preText: response?.data?.result.substring(
                0,
                startIndex - ele.start.length
              ),
              postText: response?.data?.result.substring(
                endIndex + ele.end.length,
                response?.data?.result.length
              ),
              singleCity: true,
              itinerary_version: results?.itinerary_version || 1,
              cities: results?.city_wise_itinerary?.map((ele: any) => ({
                cityName: ele?.city_name + ", " + ele?.city_country,
                averagePackagePriceForExperiencing:
                  results[0]?.approximate_trip_cost,
                description: ele?.city_itinerary_description,
                tripDetails: ele?.city_itinerary.map((subEle: any) => ({
                  ...subEle,
                  itinerary_by_time_of_the_day:
                    subEle?.itinerary_by_time_of_the_day.map(
                      (subEle_2: any) => ({
                        ...subEle_2,
                        id: v4(),
                      })
                    ),
                })),
                complete_itinerary_flights_cost: {
                  value: results?.complete_itinerary_flights_cost || "",
                  checkedFlag: true,
                  id: v4(),
                },
                complete_itinerary_hotels_cost: {
                  value: results?.complete_itinerary_hotels_cost || "",
                  checkedFlag: true,
                  id: v4(),
                },
              })),
            },
          };
        }
      } else {
        if (startIndex > 0 && endIndex > 0) {
          let subString = response?.data?.result.substring(
            startIndex,
            endIndex
          );
          results = JSON.parse(subString);
        }
        console.log("HERE IT IS RESULT REAL", results);
        if (JSON.stringify(results) !== "{}") {
          response = {
            ...response,
            data: {
              ...response.data,
              preText: response?.data?.result.substring(
                0,
                startIndex - ele.start.length
              ),
              postText: response?.data?.result.substring(
                endIndex + ele.end.length,
                response?.data?.result.length
              ),
              from: results?.from,
              numberOfdays: results?.number_of_days,
              cities: results?.results?.map((ele: any) => ({
                cityName: ele.destination_name,
                averagePackagePriceForExperiencing: ele.approximate_trip_cost,
                description: ele.destination_description,
              })),
              singleCity: false,
            },
          };
        }
      }
    }
  }

  console.log("HERE IT IS RESULT Modified 1", response);
  return response;
}

export function scrollToBottom(scrollContainerRef: any) {
  setTimeout(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        // behavior: 'smooth'
      });
    }
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  }, 10);
}

export function scrollToTop(scrollContainerRef: any) {
  setTimeout(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        // behavior: 'smooth'
      });
    }
    window.scrollTo({
      top: 0,
    });
  }, 10);
}

export function getTripName(chatData: iChatData) {
  return chatData.destinationList.reduce(
    (acc: string, ele: iCityDetailsResult, index: number) => {
      if (index === chatData?.destinationList.length - 1)
        return acc + ele.cityName.split(",")[0];
      else return acc + ele.cityName.split(",")[0] + ", ";
    },
    ""
  );
}

const data = {
  no_of_days: "6",
  id: "7a435dcb-f7ff-4826-b69a-51660baf111f",
  checked_flag: true,
  itinerary_version: 1,
  approximate_trip_cost: "",
  complete_itinerary_description:
    "Experience the bustling energy and rich cultural heritage of Mumbai and Delhi. This 5-day itinerary will take you through the iconic landmarks, vibrant markets, and unique cultural experiences of these two major Indian cities.",
  complete_itinerary_flights_cost: "$400",
  complete_itinerary_hotels_cost: "$600",
  city_wise_itinerary: [
    {
      id: "",
      image_URL: "",
      city_name: "Mumbai",
      city_country: "India",
      city_itinerary_description:
        "Mumbai, the financial capital of India, is known for its bustling streets, iconic landmarks, and vibrant nightlife. This itinerary will guide you through the best experiences Mumbai has to offer, from exploring historical sites to enjoying the city's lively atmosphere.",
      city_itinerary: [
        {
          day: "Day 1",
          city_name: "Mumbai",
          day_itinerary_description:
            "Arrive in Mumbai and start your adventure with a visit to the city's iconic landmarks and a taste of local culture.",
          itinerary_by_time_of_the_day: [
            {
              id: "",
              time_of_the_day: "Morning",
              description:
                "Arrive in Mumbai and check into your hotel. Freshen up and get ready to explore.",
            },
            {
              id: "",
              time_of_the_day: "Afternoon",
              description:
                "Visit the Gateway of India, an iconic monument overlooking the Arabian Sea. Take a leisurely walk around the area and enjoy the sea breeze.",
            },
            {
              id: "",
              time_of_the_day: "Evening",
              description:
                "Head to Marine Drive, also known as the Queen's Necklace. Enjoy a relaxing walk along the promenade and watch the sunset over the Arabian Sea.",
            },
          ],
        },
      ],
    },
    {
      id: "",
      image_URL: "",
      city_name: "Delhi",
      city_country: "India",
      city_itinerary_description:
        "Delhi, the capital city of India, is a blend of historical grandeur and modern vibrancy. This itinerary will guide you through the best experiences Delhi has to offer, from exploring ancient monuments to enjoying the city's bustling markets and cultural sites.",
      city_itinerary: [
        {
          day: "Day 2",
          city_name: "Delhi",
          day_itinerary_description:
            "Arrive in Delhi and start your adventure with a visit to the city's iconic landmarks and a taste of local culture.",
          itinerary_by_time_of_the_day: [
            {
              id: "",
              time_of_the_day: "Morning",
              description:
                "Arrive in Delhi and check into your hotel. Freshen up and get ready to explore.",
            },
            {
              id: "",
              time_of_the_day: "Afternoon",
              description:
                "Visit the Red Fort, a UNESCO World Heritage Site and a symbol of India's rich history. Explore the fort's impressive architecture and learn about its historical significance.",
            },
            {
              id: "",
              time_of_the_day: "Evening",
              description:
                "Head to Chandni Chowk, one of Delhi's oldest and busiest markets. Explore the narrow lanes, shop for souvenirs, and try some local street food (gluten-free options available).",
            },
          ],
        },
      ],
    },
  ],
};

const selectedChatData = {};
const real = {
  approximate_trip_cost: "",
  complete_itinerary_description:
    "Experience the bustling energy and rich cultural heritage of Mumbai and Delhi. This 5-day itinerary will take you through the iconic landmarks, vibrant markets, and unique cultural experiences of these two major Indian cities.",
  complete_itinerary_flights_cost: "$400",
  complete_itinerary_hotels_cost: "$600",
  itinerary_version: "1",
  city_wise_itinerary: [
    {
      city_name: "Mumbai",
      city_country: "India",
      city_itinerary_description:
        "Mumbai, the financial capital of India, is known for its bustling streets, iconic landmarks, and vibrant nightlife. This itinerary will guide you through the best experiences Mumbai has to offer, from exploring historical sites to enjoying the city's lively atmosphere.",
      city_itinerary: [
        {
          day: "Day 1",
          city_name: "Mumbai",
          day_itinerary_description:
            "Arrive in Mumbai and start your adventure with a visit to the city's iconic landmarks and a taste of local culture.",
          itinerary_by_time_of_the_day: [
            {
              id: "",
              time_of_the_day: "Morning",
              description:
                "Arrive in Mumbai and check into your hotel. Freshen up and get ready to explore.",
            },
            {
              id: "",
              time_of_the_day: "Afternoon",
              description:
                "Visit the Gateway of India, an iconic monument overlooking the Arabian Sea. Take a leisurely walk around the area and enjoy the sea breeze.",
            },
            {
              id: "",
              time_of_the_day: "Evening",
              description:
                "Head to Marine Drive, also known as the Queen's Necklace. Enjoy a relaxing walk along the promenade and watch the sunset over the Arabian Sea.",
            },
          ],
        },
      ],
    },
    {
      city_name: "Delhi",
      city_country: "India",
      city_itinerary_description:
        "Delhi, the capital city of India, is a blend of historical grandeur and modern vibrancy. This itinerary will guide you through the best experiences Delhi has to offer, from exploring ancient monuments to enjoying the city's bustling markets and cultural sites.",
      city_itinerary: [
        {
          day: "Day 2",
          city_name: "Delhi",
          day_itinerary_description:
            "Arrive in Delhi and start your adventure with a visit to the city's iconic landmarks and a taste of local culture.",
          itinerary_by_time_of_the_day: [
            {
              id: "",
              time_of_the_day: "Morning",
              description:
                "Arrive in Delhi and check into your hotel. Freshen up and get ready to explore.",
            },
            {
              id: "",
              time_of_the_day: "Afternoon",
              description:
                "Visit the Red Fort, a UNESCO World Heritage Site and a symbol of India's rich history. Explore the fort's impressive architecture and learn about its historical significance.",
            },
            {
              id: "",
              time_of_the_day: "Evening",
              description:
                "Head to Chandni Chowk, one of Delhi's oldest and busiest markets. Explore the narrow lanes, shop for souvenirs, and try some local street food (gluten-free options available).",
            },
          ],
        },
      ],
    },
  ],
};
