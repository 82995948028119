import React, { useCallback, useEffect, useState } from "react";
import { gapi } from "gapi-script";
import "./App.scss";
import { StateContext } from "./context/globalContext/context";
import styles from "./app.module.scss";
import { createTheme, ThemeProvider } from "@mui/material";
import Dialog from "./component/dialog/Dialog";

import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "./constant/environment";
import { APP_CONFIG, HomePageNav } from "./constant/common";
import { iGlobalContext } from "./context/globalContext/interface";
import AppRoutes from "./routes/AppRoutes";
import Header from "./component/reusableComponent/header/Header";
import { checkIfUserIsLoggedIn } from "./utils/loginAndLocalStorageutils";
import { useLocation } from "react-router-dom";
import { ROUTES } from "./constant";
import { getConfigAPI } from "./api/getConfig";
import { HelmetProvider } from "react-helmet-async";
import NetworkError from "./component/NetworkEror/NetworkEror";
import { getConfigMessage } from "./utils";
import { toastMessage } from "./helpers/toast/toastMessage";

const theme = createTheme({
  button: {
    main: "#f44336",
  },
  palette: {
    primary: {
      main: "#27A9E0",
      contrastText: "#696969",
    },
    secondary: {
      main: "#FCB400",
      contrastText: "black",
    },
  },
});

function App() {
  // Network Error handling
  const [networkError, setNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { pathname } = location;

  const handleRefresh = () => {
    if (navigator.onLine) {
      window.location.reload();
      setNav(HomePageNav.LANDING_PAGE);
      setNetworkError(false);
    }
  };

  const state = React.useContext(StateContext);
  const {
    loginCreateAccountState,
    setNav,
    setUserInfo,
    isMobile,
    setIsMobile,
    setAppConfig,
    userInfo,
    appConfig,
  }: iGlobalContext = state;

  React.useEffect(() => {
    if (!userInfo.loggedIn) checkIfUserIsLoggedIn(setUserInfo);
    let offineDetectInterval = window.setInterval(() => {
      if (!navigator.onLine) {
        setNetworkError(true);
        setErrorMessage(
          getConfigMessage(appConfig, APP_CONFIG.NO_INTERNET_MESSAGE.info)
        );
      }
    }, 800);

    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId:
          "176254359773-2u5j9dv1qpg1dngo1nqr7n6ttn6knvi5.apps.googleusercontent.com",
        // "643694378557-60uta6pjlhds86si515v007r4ecuulrg.apps.googleusercontent.com",
        plugin_name: "chat",
      });
    });
    return () => {
      clearInterval(offineDetectInterval);
    };
  }, []);

  /**
   * Google Analytics and getting the APP config
   */
  React.useEffect(() => {
    async function getConfig() {
      const response = await getConfigAPI();
      if (response && response) setAppConfig(response);
    }
    getConfig();
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const checkMobileView = useCallback(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [setIsMobile]);

  const handleMobileUpdate = useCallback(() => {
    setIsMobile(true);
  }, [setIsMobile]);

  const networkErrorMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.NO_INTERNET_CONNECTION)
      ?.value ??
    "NO INTERNET CONNECTION Please check your connection and try again.";

  useEffect(() => {
    const isHomeOrTopDestination =
      pathname === ROUTES.Home || pathname === ROUTES.TopDestination;

    const updateFunction = isHomeOrTopDestination
      ? checkMobileView
      : handleMobileUpdate;

    window.addEventListener("resize", updateFunction);
    updateFunction();

    return () => window.removeEventListener("resize", updateFunction);
  }, [checkMobileView, handleMobileUpdate, pathname]);

  if (networkError) {
    toastMessage.error(networkErrorMessage);
    return <NetworkError onRefresh={handleRefresh} message={errorMessage} />;
  }

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {isMobile && (
          <div className={styles.headerContainer}>
            <Header />
          </div>
        )}
        <div className={styles.contentContainer}>
          <AppRoutes />
        </div>

        {/* Dialog for Log in, create account */}
        {loginCreateAccountState.flag && (
          <div className={styles.dialogMainContainer}>
            <div className={styles.overlay}></div>
            <Dialog />
          </div>
        )}
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
