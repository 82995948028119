import {
  airConditioningIcon,
  businessCenterIcon,
  fitnessCenterIcon,
  freeWifiIcon,
  noSmookingIcon,
  onSiteParkingIcon,
  poolIcon,
  restaurantIcon,
} from "../../constant";

export const HOTEL_IMAGES_DATA = [1, 2, 3, 4, 5];

export const HOTEL_DETAILS = {
  hotelImages: ["img1", "img2"],
  hotelStar: 5,
  hotelName: "Hotel Catalan - Ciutadella",
  place: "Sane Marti",
  address: "1297 via Las Vegas, Barcelona",
  landMark: "0.5mi from Center",
  ratings: 9.1,
  ratingDescription: "Exceptional",
  reviews: 2058,
  amenities: [
    "Free WIFI",
    "Air Conditioning",
    "Non-smoking Rooms",
    "Pool",
    "Restaurant",
    "Business Center",
    "On-site Parking",
    "Fitness Center",
  ],
  aboutProperty: [
    {
      heading: "Luxurious Accomodations",
      description:
        "Nestled in the vibrant heart of Barcelona, our luxury hotel offers an unparalleled experience for discerning travelers. Each room and suite is a masterpiece of design, blending modern sophistication with timeless elegance. Guests can choose from a variety of accommodations, ranging from deluxe rooms with stunning city views to opulent penthouse suites featuring private terraces. Every detail has been meticulously crafted to ensure maximum comfort, with plush bedding, high-end furnishings, and state-of-the-art amenities. En-suite marble bathrooms come equipped with rain showers and premium toiletries, creating a sanctuary of relaxation. The hotel also offers exclusive services such as private butlers, in-room dining, and personalized concierge assistance to cater to every guest's need.",
    },
    {
      heading: "Convenient Shopping",
      description:
        "Located just steps away from some of Barcelona's most prestigious shopping destinations, our hotel is a haven for fashion enthusiasts and luxury shoppers. The iconic Passeig de Gràcia, known for its high-end boutiques and designer stores, is within walking distance. Here, guests can explore flagship stores of renowned brands such as Gucci, Chanel, and Louis Vuitton, as well as discover unique local designers and artisanal shops. The hotel also provides a personalized shopping service, offering expert advice and exclusive access to private showrooms. After a day of retail therapy, guests can return to the hotel to relax and rejuvenate, knowing their purchases will be carefully handled and delivered to their rooms.",
    },
    {
      heading: "Great Restaurants",
      description:
        "Barcelona is a gastronomic paradise, and our hotel is no exception, offering an array of dining experiences that cater to all palates. The on-site Michelin-starred restaurant is a culinary jewel, where acclaimed chefs create innovative dishes using the finest local ingredients. Guests can indulge in a gourmet tasting menu or savor à la carte options that showcase the best of Catalan and Mediterranean cuisine. For a more casual dining experience, the hotel’s stylish bistro offers a selection of international favorites and Spanish tapas, perfect for a relaxed meal. Additionally, the rooftop bar provides breathtaking views of the city skyline, where guests can enjoy expertly crafted cocktails and a selection of fine wines, making it an ideal spot for pre-dinner drinks or a nightcap.",
    },
    {
      heading: "Nightlife",
      description:
        "When the sun sets, Barcelona’s vibrant nightlife comes alive, and our hotel places guests at the epicenter of it all. From chic cocktail lounges to bustling nightclubs, the city offers a plethora of options to suit every mood and style. The hotel’s own exclusive lounge is a sophisticated venue where guests can unwind with a signature drink while listening to live music or DJ sets. For those looking to explore further, the famous El Born and Gothic Quarter neighborhoods are just a short walk away, offering a diverse array of bars, clubs, and entertainment venues. Whether you prefer an intimate jazz bar or a high-energy dance club, our concierge team can provide recommendations and secure VIP access, ensuring an unforgettable night out in Barcelona.",
    },
  ],
};

export const amenitiesIcons = [
  {
    name: "Non-smoking Rooms",
    icon: noSmookingIcon,
  },
  {
    name: "Business Center",
    icon: businessCenterIcon,
  },
  {
    name: "On-site Parking",
    icon: onSiteParkingIcon,
  },
  {
    name: "Fitness Center",
    icon: fitnessCenterIcon,
  },
  {
    name: "Pool",
    icon: poolIcon,
  },
  {
    name: "Restaurant",
    icon: restaurantIcon,
  },
  {
    name: "Air Conditioning",
    icon: airConditioningIcon,
  },
  {
    name: "Free WIFI",
    icon: freeWifiIcon,
  },
];
