import { Button } from "@mui/material";
import { eyeLight } from "../../../../constant";
import styles from "./hotel.module.scss";
import HotelCard from "../hotelCard/hotelCard";
import { Amenity } from "../../../../types";

interface iProps {
  star: number;
  hotelName: string;
  hotelLocation: string;
  hotelRating: number;
  reviewCount: number;
  cost: number;
  cancellation: string;
  Amenity: Amenity[];
  hotelList: boolean;
}

function HotelListing({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  cost,
  cancellation,
  Amenity,
  hotelList,
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.contentCard}>
          <div className={styles.hotelListCard}>
            <HotelCard
              star={star}
              hotelName={hotelName}
              hotelLocation={hotelLocation}
              hotelRating={hotelRating}
              reviewCount={reviewCount}
              Amenity={Amenity}
              hotelList={hotelList}
            />
            <div className={styles.card3}>
              <Button className={styles.flightBtn}>
                <div className={styles.iconAndText}>
                  <img src={eyeLight} alt="icon" className={styles.expIcon} />
                  <span className={styles.viewDetails}>
                    View <br /> Details
                  </span>
                </div>
              </Button>
            </div>
            <div className={styles.card4}>
              <p className={styles.price}>${cost}</p>
              <span className={styles.flightClass}>inc. Taxes & Fees</span>
              <span className={styles.cancellation}>{cancellation}</span>
              <Button className={styles.bookBtn}>Select</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelListing;
