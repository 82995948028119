import React from "react";
import styles from "./amenities.module.scss";
import { amenitiesIcons } from "../../hotelDetailsConstants";
import { eyeLight } from "../../../../constant";

interface iProps {
  aminitiesArr: string[];
}

function Amenities(props: iProps): JSX.Element {
  const { aminitiesArr = [] } = props;
  return (
    <div className={styles.amenitiesContainer}>
      <div className={styles.amenitiesListContainer}>
        {aminitiesArr.map((item, idx) => {
          const foundAmenity = amenitiesIcons.find(
            (amenity) => amenity.name === item
          );
          const { icon: amenityIcon } = foundAmenity || {};
          return (
            <div className={styles.amenityRow} key={idx}>
              {amenityIcon && (
                <img
                  src={amenityIcon}
                  alt="icon"
                  className={styles.amenityIcon}
                />
              )}
              <span className={styles.itemName}>{item}</span>
            </div>
          );
        })}
      </div>
      <div className={styles.amenitiesFooter}>
        VIEW PROPERTY AMENITIES
        <img src={eyeLight} alt="icon" className={styles.amenityFooterIcon} />
      </div>
    </div>
  );
}

export default Amenities;
