import { useState } from "react";
import RouteClassPopover from "../bookingPopover/routeClassPopover/RouteClassPopover";
import styles from "./route.module.scss";
import { BookingDetails } from "../../../types";

interface iProps {
  className?: string;
  bookingDetails?: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
}


function RouteAndClass({
  className,
  bookingDetails,
  setBookingDetails,
}: iProps): JSX.Element {
  const [routeData, setRouteData] = useState({
    id: 1,
    value: "Round Trip",
    checkedFlag: true,
  });
  const [classData, setClassData] = useState([
    {
      id: 2,
      value: "Economy",
      checkedFlag: true,
    },
    {
      id: 3,
      value: "Premium Plus",
      checkedFlag: false,
    },
    {
      id: 4,
      value: "Business",
      checkedFlag: false,
    },
    {
      id: 5,
      value: "First Class",
      checkedFlag: false,
    },
  ]);


  const selectedValues = classData
    .filter((item) => item.checkedFlag)
    .map((item) => item.value)
    .join(", ");

  return (
    <div className={`${className} ${styles.roundTripCard}`}>
      <p className={`${className} ${styles.routTitle}`}>Route & Class</p>

      <RouteClassPopover
        route={routeData}
        setRoute={setRouteData}
        classData={classData}
        setClass={setClassData}
        popoverTrigger={
          <div className={styles.roundBox}>
            {routeData.value} <br />
            {selectedValues}
          </div>
        }
      />
    </div>
  );
}

export default RouteAndClass;
function v4(): any {
  throw new Error("Function not implemented.");
}
function v1(): any {
  throw new Error("Function not implemented.");
}
